import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, Box, Typography, IconButton, Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const modalStyles = {
  small: {
    width: '420px',
  },
  medium: {
    width: '480px',
  },
  large: {
    width: '680px',
  },
};

const ModalContainer = ({ body, header, closeFunction, size, modalOpen , showButton , buttonText , buttonFunction , disabledButton}) => {
    // console.log({disabledButton})
  return (
    <Modal open={modalOpen} onClose={()=>closeFunction()}>
      <Box
        sx={{
          ...modalStyles[size],
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          bgcolor: 'background.paper',
          boxShadow: 24,
          borderRadius: 2,
          border:'none',
          outline:'none',
          maxHeight:'100vh',
          overflowY:'auto'
        }}
      >
        <Box 
          display="flex" 
          justifyContent="space-between" 
          alignItems="center" 
          mb={2} 
          textAlign="center" 
          color="#fff"
          bgcolor="#0071BC"
          border="1px solid #0071BC"
          borderRadius="0.5rem 0.5rem 0 0"
        >
          <Typography variant="h6" component="h2" sx={{flex:'1',marginLeft:'3rem'}}>
            {header}
          </Typography>
          <IconButton onClick={()=>closeFunction()}>
            <CloseIcon sx={{color:'#fff'}}/>
          </IconButton>
        </Box>
        <Box 
          sx={{p: '0 1.5rem 1.5rem 1.5rem',}}
        >
          {body}
        </Box>
        {showButton ? <Box sx={{display:'flex',alignItems:'center',justifyContent:'center',marginBottom:'1rem'}}>
           <Button onClick={buttonFunction}  variant="contained" disabled={disabledButton}>{buttonText}</Button>
        </Box> : null}
      </Box>
    </Modal>
  );
};

export default ModalContainer;
