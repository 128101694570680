import React, { useEffect, useState } from 'react';
import Cards from '../../Elements/Cards/Cards';
import "./CardListing.css"
import ModalContainer from '../ModalContainer/ModalContainer';
import PatientInfo from '../../Elements/PatientInfo/PatientInfo';
import ArrivalStatus from '../ArrivalStatus/ArrivalStatus';
import { APIPost, getLocalUser, getUploadedPrescriptionUrls, submitArrivalStatus, submitInvoice, submitPrescriptionFiles } from '../../utility/helper';
import { Loading, Notify } from 'notiflix';
import InvoiceForm from '../../Elements/InvoiceForm/InvoiceForm';
import PrescriptionUploadForm from '../../Elements/PrescriptionUploadForm/PrescriptionUploadForm';

function CardListing({ listData, setFlagForReRender, flagForReRender, activeSaveInvoiceAppId, setActiveSaveInvoiceAppId }) {
  const [currentList, setCurrentList] = useState(listData ? listData : []);
  const [selectedPatientInfo, setSelectedPatientInfo] = useState(null)
  const [modalPatientInfo, setModalPatientInfo] = useState(false)
  const [modalArrivalStatus, setModalArrivalStatus] = useState(false)
  const [arrivalModalData, setArrivalModalData] = useState(null)
  const [arrivalFormData, setArrivalFormData] = useState(null)
  const [modalGenerateInvoice, setModalGenerateInvoice] = useState(false)
  const [modalAdditionalGenerateInvoice, setModalAdditionalGenerateInvoice] = useState(false)
  const [invoiceInfo, setInvoiceInfo] = useState(null)
  const [filledInvoiceData, setFilledInvoiceData] = useState({})
  const [modalPrescription,setModalPrescription] = useState(false)
  const [prescriptionData,setPrescriptionData] = useState([])
  const [currentAppId, setCurrentAppId] = useState(null)
  const user = getLocalUser()
  useEffect(() => {
    setCurrentList(listData)
  }, listData)

  const patientInfoCloseFunction = () => {
    setModalPatientInfo(false)
  }

  const arrivalStatusCloseFunction = () => {
    setModalArrivalStatus(false)
  }

  const prescriptionCloseFunction = () => {
    setPrescriptionData([])
    setModalPrescription(false)
  }

  const generateInvoiceCloseFunction = () => {
    setModalGenerateInvoice(false)
  }

  const generateAddInvoiceCloseFunction = () => {
    setModalAdditionalGenerateInvoice(false)
  }

  function removeUploadedFilesKey(invoicePayload) {
    if (invoicePayload.allPartPayments) {
      invoicePayload.allPartPayments = invoicePayload.allPartPayments.map(payment => {
        const { uploadedFile, ...rest } = payment;
        return rest;
      });
    }
    return invoicePayload;
  }

  const validatePaymentFiles = (allPartPayments) => {
    for (const payment of allPartPayments) {
      if ((payment.paymentType === "QR Code" || payment.paymentType === "Card") && !payment.uploadedFile) {
        Notify.failure('Please upload invoice image first');
        return false;
      }
    }
    return true;
  }

  const handleGenerateInvoiceSubmit = async () => {
    console.log({ filledInvoiceData })
    // delete filledInvoiceData.appointmentDetails[0].appointmentId
    const paymentStatus = handlePaymentMode(filledInvoiceData, filledInvoiceData.appointmentDetails[0].amountToCollect)
    if (paymentStatus !== 'equal') {
      const message = paymentStatus === 'zero'
        ? 'Please Update Amount'
        : 'Invoice amount entered is not equal to consultation fees';
      Notify.failure(message);
      return;
    }
  
    if (!validatePaymentFiles(filledInvoiceData.allPartPayments)) {
      return;
    }
    if (filledInvoiceData.appointmentDetails[0].patientEmail && !handleEmailValidation(filledInvoiceData.appointmentDetails[0].patientEmail)) {
      Notify.failure('Email should be validated!');
      return
    }
    Loading.circle()
    delete filledInvoiceData.appointmentDetails[0]['isconsumableInvoice']
    delete filledInvoiceData.appointmentDetails[0]['amountToCollect']
    let invoicePayloadData = removeUploadedFilesKey(filledInvoiceData);
    setActiveSaveInvoiceAppId({
      appointmentId: filledInvoiceData.appointmentDetails[0].appointmentId,
      type: "invoice"
    })
    // let res
    const res = await submitInvoice(invoicePayloadData)
    
    if (res) {
      setFlagForReRender(!flagForReRender)
      setTimeout(handlePolling(flagForReRender), 0)
      // Loading.remove()
      // setModalGenerateInvoice(false)
      // Notify.success("Invoice Generated");
      // setTimeout(setFlagForReRender(!flagForReRender), 500)
    }
    else {
      Loading.remove()
      Notify.failure('Invoice Not Generated')
    }
  }

  const handlePaymentMode = (filledInvoiceData, cost) => {
    const { allPartPayments } = filledInvoiceData;
    let sum = allPartPayments.reduce((acc, item) => acc + Number(item.amount), 0);
  
    if (sum === 0) return 'zero';
    if (sum > cost) return 'greater';
    if (sum < cost) return 'lesser';
    return 'equal';
  };

  const handleEmailValidation = (value) => {
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    return emailPattern.test(value)
  }

  const handleAdditionlGenerateInvoiceSubmit = async () => {
    
    const paymentStatus = handlePaymentMode(filledInvoiceData, filledInvoiceData.appointmentDetails[0].consumableAmount)
    if (paymentStatus !== 'equal') {
      const message = paymentStatus === 'zero'
        ? 'Please Update Amount'
        : 'Invoice amount entered is not equal to consultation fees';
      Notify.failure(message);
      return;
    }
  
    if (!filledInvoiceData.appointmentDetails[0].consumableAmount) {
      Notify.failure('Additional Charges should be there')
      return
    }
    if (!validatePaymentFiles(filledInvoiceData.allPartPayments)) {
      return;
    }
    if (filledInvoiceData.appointmentDetails[0].patientEmail && !handleEmailValidation(filledInvoiceData.appointmentDetails[0].patientEmail)) {
      Notify.failure('Email should be validated');
      return
    }

    filledInvoiceData.appointmentDetails[0]['totalAmount'] = filledInvoiceData.appointmentDetails[0].consumableAmount
    Loading.circle()
    let invoicePayloadData = removeUploadedFilesKey(filledInvoiceData);
    setActiveSaveInvoiceAppId({
      appointmentId: filledInvoiceData.appointmentDetails[0].appointmentId,
      type: "addInvoice"
    })
    const res = await submitInvoice(invoicePayloadData)
    if (res) {
      setFlagForReRender(!flagForReRender)
      // let res
      setTimeout(handlePolling(flagForReRender), 0)
      // Loading.remove()
      // setModalAdditionalGenerateInvoice(false)
      // Notify.success("Additional Invoice Generated");
      // setTimeout(setFlagForReRender(!flagForReRender), 500)
    }
    else {
      Loading.remove()
      Notify.failure('Additional Invoice Not Generated')
    }
  }

  const handleCloseInvoice = (error) => {
    setActiveSaveInvoiceAppId(null)
    Loading.remove()
    if (error) {
      Notify.failure('Invoice generation failed!')
      return
    }
    if (activeSaveInvoiceAppId.type === "invoice") {
      setModalGenerateInvoice(false)
      
      // Notify.success("Invoice Generated");
      // setTimeout(setFlagForReRender(!flagForReRender), 500)
    } else {
      // Loading.remove()
      setModalAdditionalGenerateInvoice(false)
      // Notify.success("Additional Invoice Generated");
      // setTimeout(setFlagForReRender(!flagForReRender), 500)
    }
  }

  // Function to handle the polling
  function handlePolling(flagForReRender) {
    let pollCount = 0;  // Initialize the poll counter
    const maxPolls = 5; // Maximum number of polls
    const interval = 3000; // Polling interval in milliseconds (5 seconds)
    let timeoutId
    // Function to perform polling
    function poll(flagForReRender, activeSaveInvoiceAppId) {
      pollCount++; // Increment the poll counter
      console.log(`Polling attempt ${pollCount}`, flagForReRender);
      setFlagForReRender(!flagForReRender)
      // Perform your polling logic here
      // Example: Fetch data from an API or check some condition
      if (pollCount > 1 && !activeSaveInvoiceAppId) {
        console.log("Condition achieved, stopping polling.");
        Notify.success("Invoice Generated");
        clearTimeout(timeoutId); // Clear the timeout to stop further polling
        return; // Exit the function
    }
      // Check if the maximum number of polls has been reached
      if (pollCount < maxPolls) {
        // Schedule the next poll
        timeoutId = setTimeout(() => {
          console.log("timeout", {activeSaveInvoiceAppId})
          poll(!flagForReRender, activeSaveInvoiceAppId)
        }, interval);
      } else {
        console.log("Polling finished.");
        handleCloseInvoice(true)
      }
    }

    // Start the first poll
    poll(!flagForReRender, activeSaveInvoiceAppId);
  }

  const validateArrivalFormData = (data)=>{
    if(data.numberOfAttendants && data.numberOfAttendants<1 || data.numberOfAttendants>9){
      return false
    }
      if(data && data.recMeasure){
          let recData = data.recMeasure
          if(recData.weight && (recData.weight>200 || recData.weight<5)){
              return false
          }
          else {
            return true
          }
      }
      else if (recData.weight > 200 || recData.weight < 5) {
        return false
      }
      else {
        return true
      }
  }


  const handleArrivalStatusSubmit = async () => {
    if (arrivalFormData.receptionistStatus != null) {
      if (validateArrivalFormData(arrivalFormData)) {
        Loading.circle()
        const res = await submitArrivalStatus(arrivalFormData)
        if (res) {
          Loading.remove()
          setModalArrivalStatus(false)
          Notify.success("Arrival Status Updated");
          setTimeout(setFlagForReRender(!flagForReRender), 500)
        }
        else {
          Loading.remove()
          Notify.failure('Status Not Updated')
        }
      }
      else {
        Notify.failure('Please Fill Form Correctly')
      }
    }
    else {
      Notify.failure('Please Select A Status')
    }
  }

  const handlePrescriptionSubmit = async ()=>{
      // console.log({prescriptionData})
      if(prescriptionData && prescriptionData.length>0){
        Loading.circle()
        const imagesData = new FormData();
        const imageFiles = [...prescriptionData];
        if(imageFiles && imageFiles.length>3){
          Notify.failure('Something went wrong , please try again!!')
          return
        }
        imageFiles.forEach((file) => {
          imagesData.append("files",file);
        });
      
        const uploadedFileUrls = await getUploadedPrescriptionUrls(imagesData)
        if(uploadedFileUrls && uploadedFileUrls.result && uploadedFileUrls.result.length>0){
          setPrescriptionData([])
          let body = {
            "urls" : uploadedFileUrls.result,
            "appId":currentAppId,
            "keyType" :"Prescription",
            "source" : ""
          }
          const res = await submitPrescriptionFiles(body)
          // console.log({res})
          if(res){
            Loading.remove()
            Notify.success('Prescription Uploaded Successfully')
            setModalPrescription(false)
            setFlagForReRender(!flagForReRender)
          }
        }
        else{
          Notify.failure(`${uploadedFileUrls.description || 'Something went wrong , please try again!!'}`)
        }
      }
      else{
        setPrescriptionData([])
        Notify.failure('Please Select Images')
      }
  }


  return (
    <>
      <div className='cardListing_container'>
        {currentList && currentList.length > 0 && (
          currentList.map((appt, index) => {
            return <Cards
              key={index}
              carddata={appt}
              setSelectedPatientInfo={setSelectedPatientInfo}
              setModalPatientInfo={setModalPatientInfo}
              setModalArrivalStatus={setModalArrivalStatus}
              setArrivalModalData={setArrivalModalData}
              setModalGenerateInvoice={setModalGenerateInvoice}
              setModalAdditionalGenerateInvoice={setModalAdditionalGenerateInvoice}
              setInvoiceInfo={setInvoiceInfo}
              activeSaveInvoiceAppId={activeSaveInvoiceAppId}
              handleCloseInvoice={handleCloseInvoice}
              setModalPrescription={setModalPrescription}
              setCurrentAppId={setCurrentAppId}
            />
          })
        )}
      </div>
      <ModalContainer
        size={'small'}
        modalOpen={modalPatientInfo}
        body={selectedPatientInfo != null ? <PatientInfo data={selectedPatientInfo} /> : null}
        header="Patient Info"
        closeFunction={patientInfoCloseFunction}
        showButton={false}
        disabledButton={false}
      />
      <ModalContainer
        size={'medium'}
        modalOpen={modalArrivalStatus}
        body={arrivalModalData != null ? <ArrivalStatus data={arrivalModalData} setArrivalFormData={setArrivalFormData} /> : null}
        header="Patient Status"
        closeFunction={arrivalStatusCloseFunction}
        showButton={true}
        buttonText={"Submit"}
        buttonFunction={handleArrivalStatusSubmit}
        disabledButton={false}
      />
      <ModalContainer
        size={'large'}
        modalOpen={modalGenerateInvoice}
        body={<InvoiceForm type="invoice" data={invoiceInfo} setFilledInvoiceData={setFilledInvoiceData} amountEditable={user?.hospitalAdditionalInfo?.doctorFeesEditable || false}/>}
        header="Generate Invoice"
        closeFunction={generateInvoiceCloseFunction}
        showButton={true}
        buttonText={"Generate Invoice"}
        disabledButton={false}
        buttonFunction={handleGenerateInvoiceSubmit}
      />
      <ModalContainer
        size={'large'}
        modalOpen={modalAdditionalGenerateInvoice}
        body={<InvoiceForm type="additionalInvoice" data={invoiceInfo} setFilledInvoiceData={setFilledInvoiceData} />}
        header="Additional Charges Invoice"
        closeFunction={generateAddInvoiceCloseFunction}
        showButton={true}
        buttonText={"Generate Invoice"}
        buttonFunction={handleAdditionlGenerateInvoiceSubmit}
        disabledButton={false}
      />
      <ModalContainer
        size={'large'}
        modalOpen={modalPrescription}
        body={<PrescriptionUploadForm data={prescriptionData} setPrescriptionData={setPrescriptionData} />}
        header="Prescription"
        closeFunction={prescriptionCloseFunction}
        showButton={true}
        buttonText={"Upload"}
        buttonFunction={handlePrescriptionSubmit}
        disabledButton={false}
      />
    </>
  );
}


export default CardListing;