import React, { useEffect, useState } from 'react'
import "./HeaderFilters.css"
import { Button } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import dayjs from 'dayjs';
import { Select, Option } from '@mui/joy';
import ModalContainer from '../ModalContainer/ModalContainer';
import BookAppointmentForm from '../../Elements/BookAppointmentForm/BookAppointmentForm';
import { getLocalUser, getMobileHash, getWalkInDetails, submitBookAppointment } from '../../utility/helper';
import { Loading, Notify } from 'notiflix';
import ScheduleAppointmentForm from '../../Elements/ScheduleAppointmentForm/ScheduleAppointmentForm';
function HeaderFilters({ clinicDoctorList, setCurrentDoctor, setCurrentDate, setFlagForReRender, flagForReRender, pendingAppointmentFlag, todayDate }) {
  // console.log({ pendingWork })

  // Note: Book Appointment => WalkIn
  //       Schedule Appointment => Future Appointments 

  const [date, setDate] = useState(dayjs());
  const [maxDate, setMaxDate] = useState(dayjs());
  const yesterday = maxDate.subtract(1, 'day');
  const [selectedDoctor, setSelectedDoctor] = useState(null)
  const [modalBookAppointment, setModalBookAppointment] = useState(false)
  const [createApptObj, setCreateApptObj] = useState(null)
  const [modalScheduleAppointment,setModalScheduleAppointment] = useState(false)
  const [disableButton,setDisableButton] = useState(true)
  const user = getLocalUser()
  useEffect(() => {
    if (pendingAppointmentFlag) {
      setMaxDate(yesterday)
      setDate(yesterday)
      // setSelectedDoctor(null)
    } else {
      setMaxDate(dayjs())
      setDate(dayjs())
    }
  }, [pendingAppointmentFlag])

  useEffect(() => {
    if (clinicDoctorList && clinicDoctorList.length > 0) {
      setSelectedDoctor(clinicDoctorList[0])
      // setCurrentDoctor(clinicDoctorList[0].value)
    }
  }, [clinicDoctorList])

  const handleDateChange = (date) => {
    setDate(date);
    setCurrentDate(date.format('YYYY-MM-DD'))
    setCurrentDoctor(null)
    // setSelectedDoctor(clinicDoctorList[0])
  };

  const handleChange = (event, newValue) => {
    // console.log({newValue})
    setSelectedDoctor(newValue)
    setCurrentDoctor(newValue)
  };

  const closeBookAppointment = () => {
    setModalBookAppointment(false)
  }

  const closeScheduleAppointment = () => {
    setModalScheduleAppointment(false)
    setDisableButton(true)
  }

  const handleBookAppointmentSubmit = async (type) => {
    const currentTime = new Date().toISOString();
    // console.log("createApptObj.date",createApptObj.date,dayjs(createApptObj.date).format('YYYY-MM-DD'))
    // const datePart = type==='walkIn' ? currentTime.split('T')[0] : dayjs(createApptObj.date).format('YYYY-MM-DD');
    Loading.circle()
    let apptDetails 
    try {
      apptDetails = await getWalkInDetails(createApptObj.Mobile, user.clinicId, 
        createApptObj.DocAssigned, createApptObj.SurgeryType)
      // console.log("apptDetails",apptDetails)
    }
    catch (error) {
      Notify.failure('Something Went Wrong!')
      return
    }

    // console.log({datePart})
    if(type!='walkIn' && createApptObj.date){
      delete createApptObj["date"]
    }
    const dataObj = {
      "ActiveOwner": "Doc",
      "appBookingRequestId": "",
      "appointmentStatus": "Active",
      "AppointmentType": "OPD",
      "BDStatus": "OPDScheduled",
      "isPrepaid": false,
      "isQRAppointment": false,
      "isValid": true,
      "LastName": "",
      "LeadSource": "WalkIn",
      "opdType": "WalkIn",
      "PatientArrivalTime":type==='walkIn' ?  currentTime : createApptObj.AppointmentStartTime,
      "PaymentMode": "Cash",
      "RecStatus": "InProgress",
      "LastName": "",
      // "AppointmentDate": datePart,
      "Hospital_clinic": {
        "Hospital Name": user.clinicName ? user.clinicName : '',
        "id": user.clinicId ? user.clinicId : null
      },
    }


    let raw = { ...dataObj, ...createApptObj }
    if (apptDetails != null) {
        raw = { ...raw, ...apptDetails }
    }
   
    delete raw["appointmentStartTime"]
    delete raw["appointmentEndTime"]
    if (raw && raw.Mobile && raw.FirstName && raw.category && raw.DocAssigned) {
      const res = await submitBookAppointment(raw)
      console.log("submitBookAppointment", res)
      if (res) {
        Loading.remove()
        closeBookAppointment(false)
        closeScheduleAppointment()
        Notify.success("Appointment Created Successfully!");
        setCurrentDoctor(null)
        setFlagForReRender(!flagForReRender)
      }
      else {
        Loading.remove()
        Notify.failure('Appointment Creation Failed!')
      }
      console.log({ res })
    }
    else {
      Loading.remove()
      if (!raw.Mobile) {
        Notify.failure('Please Update Mobile Field')
      }
      else if (!raw.FirstName) {
        Notify.failure('Please Update Patient Name Field')
      }
      else if (!raw.category) {
        Notify.failure('Please Update Select Procedure Field')
      }
      else if (!raw.DocAssigned) {
        Notify.failure('Please Update Select Doctor Field')
      } else if (!raw.CashAmount && type==='walkIn') {
        Notify.failure('Please Check Cash Amount')
      } else if (!raw.AppointmentStartTime) {
        Notify.failure('Please Check walkin details')
      }
    }
  }
  // console.log("disabledButtonHere",disableButton)
  return (
    <>
      <h2 className='headerFilter_header'>{pendingAppointmentFlag ? 'Pending' : date && (date.format('YYYY-MM-DD') === dayjs().format('YYYY-MM-DD')) ? `Today's` : date.format('DD MMM YYYY') + " - "} Appointments</h2>
      {!pendingAppointmentFlag ? <div className='headerFilter_container'>
        <div className='headerFilter_filtersContainer'>
          <div className='headerFilter_filter' style={{ color: '#525252' }}>
            <p>Select Date: </p>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                value={date}
                onChange={handleDateChange}
                minDate={dayjs('2020-01-01')}
                // maxDate={maxDate}
                // shouldDisableDate={(date) => date > maxDate}
                className='headerFilter_datePicker'
                disabled={pendingAppointmentFlag}
                format="DD-MM-YYYY"
              />
            </LocalizationProvider>
          </div>
          <div className='headerFilter_filter' style={{ color: pendingAppointmentFlag ? 'lightgrey' : '#525252' }}>
            <p>Select Doctor: </p>
            <Select
              onChange={handleChange}
              value={selectedDoctor?.value}
              label='Select Doctor'
              indicator={<KeyboardArrowDown />}
              className='headerFilter_doctorSelect'
              disabled={pendingAppointmentFlag}
            >
              {
                clinicDoctorList && clinicDoctorList.length > 0 && clinicDoctorList.map((item, index) => {
                  return <Option value={item.value}>{item.label}</Option>
                })
              }
            </Select>
          </div>
        </div>
        <div className='headerFilter_buttonGroup'>
          <Button variant="outlined" className='headerFilter_createButton' onClick={() => setModalScheduleAppointment(true)}>
            Schedule Appointments
          </Button>
          <Button variant="contained" className='headerFilter_createButton' style={{fontWeight:'600'}}onClick={() => setModalBookAppointment(true)}>
            Create Walk-In
          </Button>
        </div>
      </div> : null}
      <ModalContainer
        size={'small'}
        modalOpen={modalBookAppointment}
        body={<BookAppointmentForm setCreateApptObj={setCreateApptObj} amountEditable={user?.hospitalAdditionalInfo?.doctorFeesEditable || false}/>}
        header="Book Appointment"
        closeFunction={closeBookAppointment}
        showButton={true}
        buttonText={"Submit"}
        buttonFunction={()=>handleBookAppointmentSubmit('walkIn')}
        disabledButton={false}
      />
      <ModalContainer
        size={'large'}
        modalOpen={modalScheduleAppointment}
        body={<ScheduleAppointmentForm setCreateApptObj={setCreateApptObj} setDisableButton={setDisableButton} amountEditable={user?.hospitalAdditionalInfo?.doctorFeesEditable || false} />}
        header="Schedule Appointment"
        closeFunction={closeScheduleAppointment}
        showButton={true}
        buttonText={'Book Appointment'}
        disabledButton={disableButton}
        buttonFunction={()=>handleBookAppointmentSubmit('schedule')}
      />
    </>

  )
}

export default HeaderFilters