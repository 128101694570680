import React, { useEffect, useState } from 'react'
import HeaderFilters from '../../Components/HeaderFilters/HeaderFilters'
import CardListing from '../../Components/CardListing/CardListing'
import dayjs from 'dayjs'
import { fetchDoctorList, fetchPendingApptData, fetchTodaysApptData, getLocalUser } from '../../utility/helper'
import NoDataFound from '../NoDataFound/NoDataFound'
import InvoiceForm from '../../Elements/InvoiceForm/InvoiceForm'
import Loader from '../../Components/Loader'

function PaitentListing() {
  const [pendingWork, setPendingWork] = useState(false)
  const [disabledDoctorSelect, setDisabledDoctorSelect] = useState(false)
  const [disabledDateSelect, setDisabledDateSelect] = useState(false)
  const [clinicDoctorList, setClinicDoctorList] = useState([])
  const [currentAppointmentList, setCurrentAppointmentList] = useState([])
  const todayDate = dayjs();
  const yesterday = todayDate.subtract(1, 'day').format('YYYY-MM-DD');
  const [currentDoctor, setCurrentDoctor] = useState('')
  const [currentDate, setCurrentDate] = useState(yesterday)
  const [flagForReRender, setFlagForReRender] = useState(true)
  const [loadingForAppointmentsFetch, setLoadingForAppointmentFetch] = useState(true)
  const user = getLocalUser()
  const [pendingAppointmentFlag, setPendingAppointmentFlag] = useState(true)
  const [activeSaveInvoiceAppId, setActiveSaveInvoiceAppId] = useState(null)


  async function fetchAndSetPendingWork() {
    try {
      setLoadingForAppointmentFetch(true)
      const response = await fetchPendingApptData(yesterday, user?.clinicId);
      console.log("fetchPendingApptData", { response })
      if (response) {
        setCurrentAppointmentList(response)
        setLoadingForAppointmentFetch(false)
      } else {
        setCurrentDate(todayDate.format('YYYY-MM-DD'))
        setPendingAppointmentFlag(false)
      }
    } catch (error) {
      setLoadingForAppointmentFetch(false)
      setCurrentDate(todayDate.format('YYYY-MM-DD'))
      setPendingAppointmentFlag(false)
    }

  }
  console.log({ currentDate })

  // useEffect(() => {
  //     fetchAndSetPendingWork()
  // },[pendingAppointmentFlag, flagForReRender])

  useEffect(() => {
    if (pendingAppointmentFlag) {
      fetchAndSetPendingWork()
    }
  }, [flagForReRender, pendingAppointmentFlag])

  useEffect(() => {
    setCurrentAppointmentList([])
    setLoadingForAppointmentFetch(true)
    console.log({ currentDate, currentDoctor, flagForReRender })
    if (currentDate && currentDoctor && !pendingAppointmentFlag) {
      fetchDateDoctorData()
    } else if (currentDate && !currentDoctor && !pendingAppointmentFlag) {
      fetchClinicDoctors(user?.clinicId, currentDate)
    }
  }, [currentDate, currentDoctor, flagForReRender])

  async function fetchDateDoctorData() {
    try {
      setLoadingForAppointmentFetch(true)
      const response = await fetchTodaysApptData(currentDate, user?.clinicId, currentDoctor);
      // fetchClinicDoctors(user.clinicId, currentDate)
      setCurrentAppointmentList(response || [])
      setLoadingForAppointmentFetch(false)
    } catch (error) {
      setCurrentAppointmentList([])
      console.error(error);
      setLoadingForAppointmentFetch(false)
    }
  }

  async function fetchClinicDoctors(id, date) {
    try {
      const response = await fetchDoctorList(id, date);
      if (response != null) {
        const docList = response.map((item) => {
          return {
            value: item.doctor_id,
            label: item.doctor_name
          }
        })
        setClinicDoctorList(docList || [])
        setCurrentDoctor(docList[0]?.value || [])
        // if (docList.length) {
        //   setCurrentDoctor(docList[0].value)
        // } else {
        //   setClinicDoctorList(null)
        // }
      } else {
        setClinicDoctorList([])
        setCurrentDoctor(null)
        setCurrentAppointmentList([])
        setLoadingForAppointmentFetch(false)
      }
    } catch (error) {
      setClinicDoctorList([])
      setCurrentDoctor(null)
      setCurrentAppointmentList([])
      setLoadingForAppointmentFetch(false)
      console.error(error);
    }
  }

  return (
    <>
      <HeaderFilters
        clinicDoctorList={clinicDoctorList}
        setCurrentDate={setCurrentDate}
        setCurrentDoctor={setCurrentDoctor}
        setFlagForReRender={setFlagForReRender}
        flagForReRender={flagForReRender}
        pendingAppointmentFlag={pendingAppointmentFlag}
      />
      {loadingForAppointmentsFetch ? <Loader /> :
        currentAppointmentList && currentAppointmentList.length > 0 ?
          <CardListing
            listData={currentAppointmentList}
            setFlagForReRender={setFlagForReRender}
            flagForReRender={flagForReRender}
            setActiveSaveInvoiceAppId={setActiveSaveInvoiceAppId}
            activeSaveInvoiceAppId={activeSaveInvoiceAppId}
          />
          :
          <NoDataFound pendingWork={pendingWork} type='patientListing'/>
      }

    </>
  )
}

export default PaitentListing 