import React from 'react'
import "./PatientInfo.css"

function PatientInfo({ data }) {
  console.log({data})
  return (
    <div className='patientInfo_container'>
      {
        Object.entries(data).map(([key, value], index) => (
          <div key={index} className='patientInfo_infoContainer'>
            <p className='patientInfo_key'>{key.replace(/_/g, ' ').replace(/^./, match => match.toUpperCase())}</p>
            <p className='patientInfo_value'>{value && value != null ? value : "--"}</p>
          </div>
        ))}
    </div>
  )

}

export default PatientInfo