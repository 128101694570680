import React from 'react'
import './Cards.css'
import Card from '@mui/material/Card';
import { Button, CardActions, CardContent, Tooltip } from '@mui/material';
import { Notify } from 'notiflix';
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import { convertTimestamp, getSignedUrl, handlePrescriptionView, utcDateFormatToLocalFormat } from '../../utility/helper';
function Cards({
  carddata,
  setSelectedPatientInfo,
  setModalPatientInfo,
  setModalArrivalStatus,
  setArrivalModalData,
  setModalGenerateInvoice,
  setModalAdditionalGenerateInvoice,
  setInvoiceInfo,
  activeSaveInvoiceAppId,
  setActiveSaveInvoiceAppId,
  handleCloseInvoice,
  setModalPrescription,
  setCurrentAppId,
  currentAppId
}) {
  if (activeSaveInvoiceAppId && activeSaveInvoiceAppId.appointmentId == carddata._id && ((activeSaveInvoiceAppId.type === "invoice" && carddata.InvoiceUrl) || (activeSaveInvoiceAppId.type === "addInvoice" && carddata.consumableInvoiceUrl))) {
    console.log({ activeSaveInvoiceAppId, carddata })
    handleCloseInvoice()
  }

  let paidStatus = 'UNPAID'
  let paidAmount = 0
  if (carddata) {
    if (carddata.InvoiceUrl && carddata.InvoiceUrl !== null) {
      if (carddata.consumableAmount && carddata.consumableAmount > 0 && carddata.cashAmount && carddata.cashAmount > 0) {
        paidAmount = carddata.consumableAmount + carddata.cashAmount
        paidStatus = "PAID"
      } else {
        paidAmount = carddata.cashAmount || 0
        paidStatus = "PAID"
      }
    } else {
      paidAmount = carddata.amountToCollect || 0
      paidStatus = "UNPAID"
    }
  }

  const handlePatientInfo = (data) => {
    // console.log("data.PatientArrivalTime",data,convertTimestamp(data.PatientArrivalTime))
    let obj = {
      patient_Id: data.patientId ? data.patientId : null,
      appointment_Id: data._id ? data._id : data.appId ? data.appId : null,
      doctor_Name: data.doctorName ? data.doctorName : null,
      bd_Name: data.bdName ? data.bdName : null,
      bd_Number: data.bdNumber ? data.bdNumber : null,
      arrival_Date_Time: data.PatientArrivalTime ? convertTimestamp(data.PatientArrivalTime).toLocaleUpperCase() : null,
      invoice_Generation_Time: data.invoiceDate ? utcDateFormatToLocalFormat(data.invoiceDate) : null
    }
    setSelectedPatientInfo(obj)
    setModalPatientInfo(true)
  }

  const handleArrivalStatusModal = (data) => {
    setModalArrivalStatus(true)
    setArrivalModalData(data)
  }

  const handleGenerateInvoice = (data) => {

    if (data.arrivalStatus.toLowerCase() != 'arrived') {
      Notify.failure("Please mark patient arrival status");
      return
    }
    else {
      setInvoiceInfo(data)
      if (data.InvoiceUrl) {
        setModalGenerateInvoice(false)
        setModalAdditionalGenerateInvoice(true)
      }
      else {
        setModalAdditionalGenerateInvoice(false)
        setModalGenerateInvoice(true)
      }
    }
  }

  const handleInvoiceError = (data) => {
    if (!data.InvoiceUrl) {
      Notify.failure('Please update Patient Arrival Status')
    }
    else {
      Notify.failure('Additional Invoice already generated')
    }
  }

  const handlePrescriptionModal = () => {
    setModalPrescription(true)
    setCurrentAppId(carddata._id || carddata.appId || null)
  }


  

  return (
    <>
      <Card sx={{ width: '31%', position: 'relative', minHeight: `${carddata.appointmentStatus && carddata.appointmentStatus === 'Cancelled' ? '291px' : 'auto'}`, boxShadow: '0px 1px 10px 0px #44444433' }} className='cards_container'>
        <CardContent className='cards_CardContent cards_patientInfo'>
          <p className='cards_title'>{carddata.patientName ? carddata.patientName : ''}</p>
          <img src="https://img.pristyncare.com/amp_pictures%2Fep_info-filled.png" style={{ cursor: 'pointer', pointerEvents: 'auto' }} onClick={() => { handlePatientInfo(carddata) }} />
        </CardContent>
        <CardContent className='cards_CardContent'>
          <p>Appointment Time:</p>
          <p>{carddata?.appointmentTime ? convertTimestamp(carddata.appointmentTime) : '--:--'}</p>
        </CardContent>
        <CardContent className='cards_CardContent'>
          <p>BD Name:</p>
          <p> {carddata.bdName ? carddata.bdName : ''}</p>
        </CardContent>
        <CardContent className='cards_CardContent'>
          <p>Amount:</p>
          <div style={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
            <span style={{ color: `${paidStatus.toLowerCase() != 'paid' ? '#000' : '#36BD85'}`, fontWeight: '600' }}>₹{paidAmount}</span>
            <div
              style={{
                border: `1px solid ${paidStatus.toLowerCase() != 'paid' ? '#FF7469' : '#36BD85'}`,
                color: `${paidStatus.toLowerCase() != 'paid' ? '#FF7469' : '#36BD85'}`,
                padding: '5px',
                borderRadius: '8px',
                fontSize: '14px',
                fontWeight: '700',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                gap: '5px'
              }}>
              {
                paidStatus.toLowerCase() === 'paid' ?
                  <img src="https://img.pristyncare.com/amp_pictures%2FOk.png" />
                  : null
              }
              <span>{paidStatus}</span>
            </div>
          </div>
        </CardContent>
        <CardContent className='cards_CardContent'>
          <p>Invoice:</p>
          {carddata.InvoiceUrl ?
            <div className='cards_cardInvoiceContainer'>
              <a href={carddata.InvoiceUrl} target='_blank'>Invoice</a>
              {carddata.consumableInvoiceUrl ? <div className='divideLine'></div> : ''}
              {carddata.consumableInvoiceUrl ? <a href={carddata.consumableInvoiceUrl} target='_blank'>Add. Invoice</a> : ''}
            </div>
            :
            <p>Pending</p>
          }
        </CardContent>
        <CardContent className='cards_CardContent'>
          <p>Prescription:</p>
          {carddata.prescriptions && carddata.prescriptions.length > 0 ?
            <div className='cards_cardInvoiceContainer'>
              <a onClick={() => handlePrescriptionView(carddata.prescriptions)} target='_blank' style={{cursor:'pointer'}}>View</a>
            </div>
            : carddata.appointmentStatus && carddata.appointmentStatus != 'Cancelled' ? 
            <label style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
              <img src="https://img.pristyncare.com/uploadImage_receptonistApp.png" onClick={() => handlePrescriptionModal()} />
            </label> : "--"
          }
        </CardContent>
        {(carddata.appointmentStatus && carddata.appointmentStatus != 'Cancelled' || !carddata.appointmentStatus) ?
          <CardActions className='cards_actionButtons'>
            <>
              {carddata.arrivalStatus.toLowerCase() !== 'arrived' || carddata.consumableInvoiceUrl ? (
                <Tooltip
                  title={(carddata.arrivalStatus.toLowerCase() !== 'arrived' && !carddata.InvoiceUrl) ? 'Please mark patient as arrived to generate invoice' : carddata.arrivalStatus.toLowerCase() === 'arrived' && carddata.InvoiceUrl ? 'Additional Invoice already generated' : 'Please update Patient Arrival Status'}
                  PopperProps={{
                    modifiers: [
                      {
                        name: 'offset',
                        options: {
                          offset: [0, -10],
                        },
                      },
                    ],
                  }}
                >
                  <span>
                    <Button
                      size="small"
                      disabled={carddata.arrivalStatus.toLowerCase() !== 'arrived' || carddata.consumableInvoiceUrl}
                      variant="contained"
                      className="cards_generateButton"
                      onClick={() => handleInvoiceError(carddata)}
                    >
                      {carddata.InvoiceUrl ? 'Generate Add. Invoice' : 'Generate Invoice'}
                    </Button>
                  </span>
                </Tooltip>
              ) : (
                <Button
                  size="small"
                  disabled={carddata.arrivalStatus.toLowerCase() !== 'arrived' || carddata.consumableInvoiceUrl}
                  variant="contained"
                  className="cards_generateButton"
                  onClick={() => handleGenerateInvoice(carddata)}
                >
                  {carddata.InvoiceUrl ? 'Generate Add. Invoice' : 'Generate Invoice'}
                </Button>
              )}
            </>

            <Button size="small" variant='outlined' cursor='pointer' className={
              carddata.arrivalStatus.toLowerCase() === 'arrived' ? "cards_arrived" : carddata.arrivalStatus.toLowerCase() === "notarrived" ? "cards_notArrived" : carddata.arrivalStatus.toLowerCase() === "doctornotavailable" ? "cards_doctorNotAvailable" : 'cards_arrivalButton'} onClick={() => handleArrivalStatusModal(carddata)} style={{ minWidth: '40%' }}>
              <span>{carddata.arrivalStatus.toLowerCase() === 'arrived' ? "Arrived" : carddata.arrivalStatus.toLowerCase() === "notarrived" ? "Not Arrived" : carddata.arrivalStatus.toLowerCase() === "doctornotavailable" ? "Dr. Not Available" : 'Arrival Status'}</span>
              <ArrowOutwardIcon sx={{ color: carddata.arrivalStatus.toLowerCase() === 'arrived' || carddata.arrivalStatus.toLowerCase() === "notarrived" || carddata.arrivalStatus.toLowerCase() === "doctornotavailable" ? "#fff" : '' }} />
            </Button>
          </CardActions> : null}

        {carddata.isOPDWalkIn ? carddata.isWalkInType ?
          <div className='card_appointmentType'>Walk-In</div> : <div className='card_appointmentType'>Follow Up</div> : null}
        {
          carddata.appointmentStatus && carddata.appointmentStatus === 'Cancelled' ? <div className='card_appointmentType card_cancelled'>Cancelled</div> : null
        }
      </Card>


    </>
  )
}

export default Cards