import React, { useEffect, useState } from 'react';
import { TextField, MenuItem } from '@mui/material';
import Button from '@mui/material/Button';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import "./BookAppointmentForm.css"
import { getCategoryAndDoctors, getDocFees, getLeadDataByNumber, getLocalUser, getWalkInDetails } from '../../utility/helper';
import { Notify } from 'notiflix';
import { esES } from '@mui/x-date-pickers/locales';

const BookAppointmentForm = ({ setCreateApptObj ,amountEditable}) => {
  const [formData, setFormData] = useState({
    Mobile: '',
    FirstName: '',
    secondaryUserName: '',
    category: '',
    DocAssigned: '',
    docName: '',
    Disease: "",
    SurgeryType: "",
    walkinType: '',
    AppointmentStartTime: '',
    AppointmentEndTime: '',
    CashAmount: ''
  });

  const user = getLocalUser()
  useEffect(() => {
    console.log("setCreateApptObj", { formData })
    if (formData) {
      setCreateApptObj(formData)
    }
  }, [formData])

  const [categoryList, setCategoryList] = useState([])
  const [combineList, setcombineList] = useState([])
  const [patientCount, setPatientCount] = useState(1);
  const [doctorList, setDoctorList] = useState([])
  const [mobileError, setMobileError] = useState('')
  const [docFees, setDocfees] = useState(undefined)
  const [walkinTypeState, setWalkinTypeState] = useState(null)
  useEffect(() => {
    async function fetchCategoryAndDoctors() {
      try {
        const response = await getCategoryAndDoctors(user.clinicId);
        if (response != null) {
          const tempCategoryList = response ? response.map(item => { return { value: item.category, label: item.category } }) : []
          setCategoryList(tempCategoryList)
          setcombineList(response)
        }
      } catch (error) {
        console.error(error);
      }
    }
    fetchCategoryAndDoctors();
  }, [])



  useEffect(() => {
    async function fetchDoctorFees() {
      try {
        setDocfees(undefined)
        setFormData((formData) => ({
          ...formData,
          CashAmount: undefined,
          TotalAmount: undefined
        }));
        const res = await getDocFees(formData.DocAssigned, formData.category)
        if (res != null) {
          const feeObj = res && res.length > 0 ? res[0].consultationFees : null
          console.log({ feeObj })
          setFormData((formData) => ({
            ...formData,
            CashAmount: feeObj?.actualFee ? feeObj.actualFee : 0,
            TotalAmount: feeObj?.actualFee ? feeObj.actualFee : 0
          }));
          setCreateApptObj(formData)
          setDocfees(feeObj?.actualFee ? feeObj.actualFee : 0)
        } else {
          console.log("else condition")
          setFormData((formData) => ({
            ...formData,
            CashAmount: 0,
            TotalAmount: 0
          }));
          setCreateApptObj(formData)
        }
      } catch (error) {
        setDocfees(undefined)
        setFormData((formData) => ({
          ...formData,
          CashAmount: undefined,
          TotalAmount: undefined
        }));

      }
    }
    if (formData && formData.category != '' && formData.DocAssigned != '') {
      fetchDoctorFees()
    }
  }, [formData.DocAssigned, formData.category])

  // Debounce function 
  let timeoutId;
  const debounce = (func, delay) => {
    return function (...args) {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };
  const handleDebounce = debounce(() => fetchPatientName(), 1000)
  // how to call debounce function 


  useEffect(() => {
    // console.log("getAppointmentType", {formData})
    setWalkinTypeState(null)
    const currentTime = new Date().toISOString();
    const datePart = currentTime.split('T')[0]
    setFormData((formData) => ({
      ...formData,
      ['walkinType']: null,
      ["AppointmentStartTime"]: null,
      ["AppointmentEndTime"]: null,
    }));
    async function getAppointmentType() {
      // console.log("herehere",formData.Mobile, user.clinicId, formData.DocAssigned, formData.SurgeryType)
      const apptDetails = await getWalkInDetails(formData.Mobile, user.clinicId, formData.DocAssigned, formData.SurgeryType)
      if (!apptDetails) {
        Notify.failure("Please Choose different Category");
        setWalkinTypeState(null)
        setFormData((formData) => ({
          ...formData,
          ['walkinType']: null,
          ["AppointmentStartTime"]: null,
          ["AppointmentEndTime"]: null,
        }));
        return
      }
      setFormData((formData) => ({
        ...formData,
        ['walkinType']: apptDetails.walkinType ? apptDetails.walkinType : "new",
        ["AppointmentStartTime"]: apptDetails.appointmentStartTime,
        ["AppointmentEndTime"]: apptDetails.appointmentEndTime,

      }));
      setCreateApptObj(formData)
      setWalkinTypeState(apptDetails.walkinType ? apptDetails.walkinType : "new")
    }
    if (validateMobileNumber(formData.Mobile) && formData.DocAssigned != '' && formData.category != '') {
      setFormData((formData) => ({
        ...formData,
        ["AppointmentDate"]: datePart
      }));
      getAppointmentType()
    }
  }, [formData.Mobile, formData.DocAssigned, formData.SurgeryType, formData.category])


  async function fetchPatientName() {
    // console.log("fetchPatientName", formData.Mobile)
    try {
      const res = await getLeadDataByNumber(formData.Mobile)
      if (res != null) {
        const patientName = await res[0].patientName
        setFormData((formData) => ({
          ...formData,
          ['FirstName']: patientName,
        }));
        setCreateApptObj(formData)
      }
    } catch (error) {

    }
  }
  const validateMobileNumber = (mobileNumber) => {
    if (mobileNumber !== '') {
      if (mobileNumber.length > 10) {
        setMobileError('Mobile number cannot exceed 10 digits');
        return false;
      } else if (mobileNumber.length < 10) {
        setMobileError('Mobile number cannot be less than 10 digits');
        return false;
      } else if (!/^[6-9]/.test(mobileNumber)) {
        setMobileError('Mobile number can only start with 6, 7, 8, or 9');
        return false;
      } else {
        setMobileError('');
        return true;
      }
    } else {
      setMobileError('');
      return true;
    }
  }

  useEffect(() => {
    if (formData && formData.Mobile != '' && formData.Mobile.length === 10 && validateMobileNumber(formData.Mobile)) {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(() => {
        fetchPatientName();
      }, 1000);
      // handleDebounce()
    }
  }, [formData.Mobile])




  const handleChange = async (event) => {
    // console.log("handleChange", event.target.name, event.target.value)
    if (event.target.name === 'Mobile' && event.target.value != '') {
      validateMobileNumber(event.target.value)
      // if (event.target.value.length > 10) {
      //   setMobileError('Mobile number cannot exceed 10 digits');
      // }
      // else if(event.target.value.length < 10){
      //   setMobileError('Mobile number cannot be less than 10 digits');
      // }
      // else{
      //   setMobileError('')
      // }
      formData.DocAssigned
      setWalkinTypeState(null)
      setDoctorList([])
      setFormData((formData) => ({
        ...formData,
        ['DocAssigned']: '',
        ['docName']: '',
        category: '',
        SurgeryType: '',
        Disease: '',
        [event.target.name]: event.target.value.slice(0, 10),
      }))
    }
    if (event.target.name === 'category') {
      setDoctorList([])
      const selectedData = combineList.filter((item, index) => {
        if (item.category === event.target.value) {
          return item.doctors
        }
      })
      // console.log({ selectedData })
      if (selectedData && selectedData.length > 0) {
        const tempDoctorList = selectedData[0].doctors.map((doc) => {
          if (doc.doctorId) {
            return {
              value: doc.doctorId,
              label: doc.doctorName
            }
          }
        })
        setTimeout(() => {
          tempDoctorList && setDoctorList(tempDoctorList)
        }, 0)
      }
      // console.log("selectedData[0].surgeryType",selectedData[0].surgeryType)
      setFormData((formData) => ({
        ...formData,
        ['DocAssigned']: '',
        ['docName']: '',
        [event.target.name]: event.target.value,
        "Disease": event.target.value,
        "SurgeryType": selectedData[0].surgeryType ? selectedData[0].surgeryType : ''
      }));

    }
    else if (event.target.name === 'doctor') {
      const selectedDoctor = doctorList.find((doc) => doc.value === event.target.value);
      setFormData((formData) => ({
        ...formData,
        ['DocAssigned']: event.target.value,
        ['docName']: selectedDoctor.label
      }));
    }
    else if(event.target.name==='DoctorFee'){
      setFormData((formData) => ({
        ...formData,
        CashAmount: Number(event.target.value),
        TotalAmount: Number(event.target.value)
      }));
    }
    else {
      setFormData((formData) => ({
        ...formData,
        [event.target.name]: event.target.value,
      }));
    }
    setCreateApptObj(formData)
    // console.log("selectedDoctor", {formData})
  };

  const handleAddPatient = () => {
    setPatientCount(patientCount + 1);
    setFormData((formData) => ({
      ...formData,
      ['secondaryUserName']: '',
    }));
  };

  const handleRemovePatient = () => {
    if (patientCount > 1) {
      setPatientCount(patientCount - 1);
      const { secondaryUserName, ...restFormData } = formData;
      setFormData(restFormData);
    }
  };
  console.log({ formData })
  return (
    <form>
      <TextField
        size="small"
        label="Mobile Number"
        name="Mobile"
        value={formData.Mobile}
        onChange={handleChange}
        fullWidth
        margin="normal"
        type="number"
      />
      {mobileError && <div style={{ color: `${mobileError != '' ? 'red' : 'green'}`, fontSize: '12px' }}>{mobileError}</div>}
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <TextField
          size="small"
          label="Primary Patient Name"
          name="FirstName"
          value={formData.FirstName}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
        {patientCount > 1 && (
          <Button onClick={handleRemovePatient} startIcon={<PersonRemoveIcon style={{ fontSize: '35px', marginLeft: '1rem', color: '#000' }} />}>
          </Button>
        )}
        {patientCount === 1 && (
          <Button onClick={handleAddPatient} startIcon={<PersonAddIcon style={{ fontSize: '35px', marginLeft: '1rem', color: '#000' }} />}>
            {/* <img src=""/> */}
          </Button>
        )}
      </div>
      {patientCount > 1 && (
        <TextField
          size="small"
          label="Secondary Patient Name"
          name="secondaryUserName"
          value={formData.secondaryUserName}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
      )}
      <TextField
        size="small"
        select
        label="Select Procedure"
        name="category"
        value={formData.category}
        onChange={handleChange}
        fullWidth
        margin="normal"
      >
        {categoryList.map((item, index) => (
          <MenuItem key={index} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </TextField>
      {formData.category && doctorList.length ? <TextField
        size="small"
        select
        label="Select Doctor"
        name="doctor"
        value={formData.doctor}
        onChange={handleChange}
        fullWidth
        margin="normal"
      >
        {doctorList.map((doc, index) => (
          <MenuItem key={index} value={doc.value}>
            {doc.label}
          </MenuItem>
        ))}
      </TextField> : null}
      {walkinTypeState && docFees !== undefined && formData.category && doctorList.length && formData.DocAssigned ? <div className='bookAppointment_textContainer'>
        <div className='bookAppointment_textLabels'>
          <p>Appointment Type</p>
          <p>{walkinTypeState || "new"}</p>
        </div>
        {walkinTypeState && docFees !== undefined && formData.category && doctorList.length && formData.DocAssigned ? <div className='bookAppointment_textLabels'>
          <p>Consultation Fee</p>
          {/* <p>₹ {docFees}</p> */}
          <TextField
            size="small"
            label="Doctor Fee"
            name="DoctorFee"
            defaultValue={formData.TotalAmount}
            onChange={handleChange}
            margin="normal"
            type="number"
            style={{width:'30%'}}
            inputProps={{ min: 0 }}
            disabled={!amountEditable}
          />
        </div> : null}
      </div> : null}
    </form>
  );
};

export default BookAppointmentForm;
