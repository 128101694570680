import React, { useState } from 'react';
import { Box, Typography } from '@mui/material';
import './TimeSlots.css';

const TimeSlots = ({ data ,setSelectedDoctorSlot}) => {
  const [selectedSlot, setSelectedSlot] = useState(null);

  const formatTime = (hour, minute) => {
    let hr = parseInt(hour, 10);
    const period = hr >= 12 ? 'PM' : 'AM';
    hr = hr % 12 || 12;
    return `${hr}:${minute} ${period}`;
  };

  const handleSlotClick = (slot) => {
    setSelectedSlot(slot);
    setSelectedDoctorSlot(slot)
    console.log('Selected Slot:', slot);
  };

  return (
    <Box className="timeSlotsContainer">
      {data && data.map((slot, index) => {
        const startTime = formatTime(slot.start.hr, slot.start.mm);
        const endTime = formatTime(slot.end.hr, slot.end.mm);
        const isSelected = selectedSlot === slot;
        return (
          <Typography
            key={index}
            className={`timeSlot ${isSelected ? 'selected' : ''}`}
            onClick={() => handleSlotClick(slot)}
          >
            {startTime} - {endTime}
          </Typography>
        );
      })}
    </Box>
  );
};

export default TimeSlots;
