import React, { useState } from 'react';
import "./HeaderBar.css";
import LogoutIcon from '@mui/icons-material/Logout';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { getLocalUser, handleUserLogOut } from '../../utility/helper';
import { Popover, IconButton, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import AssessmentIcon from '@mui/icons-material/Assessment';
import HomeIcon from '@mui/icons-material/Home';

function HeaderBar() {
  const user = getLocalUser();
  const [anchorEl, setAnchorEl] = useState(null);
  // const navigate = useNavigate();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handleReport = () => {
    window.location.href = '/report'
    handleClose();
  };

  const handleHome = ()=>{
    window.location.href = '/home'
    handleClose();
  }

  return (
    <div
      style={{
        backgroundColor: '#002B47',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '0.5rem 2rem'
      }}>
      <img src="https://img.pristyncare.com/amp_pictures%2FrecepitonistWebLogo.png" alt="Logo" />
      {user && user.clinicName && user.clinicName !== '' && (
        <div className='headerBar_container'>
          <div>
            <img src="https://img.pristyncare.com/amp_pictures%2FclinicAvatar.png" alt="Clinic Avatar" />
            <p>{user.clinicName}</p>
          </div>
          <IconButton aria-describedby={id} onClick={handleClick} style={{ color: '#fff' }}>
            <MoreVertIcon />
          </IconButton>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}           
          >
            <div style={{ padding: '1rem' }}>
            <Button
                  onClick={handleHome}
                  style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start',color:'#525252'}}
                >
                    <HomeIcon sx={{ marginRight: '0.5rem' }}/>
                    Home
                </Button>
              <Button
                  onClick={handleReport}
                  style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start',color:'#525252'}}
                >
                    <AssessmentIcon sx={{ marginRight: '0.5rem' }}/>
                    Reporting Module
                </Button>
              <Button
                onClick={handleUserLogOut}
                style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start',color:'#525252' }}
              >
                <LogoutIcon sx={{ marginRight: '0.5rem' }} />
                Logout
              </Button>
            </div>
          </Popover>
        </div>
      )}
    </div>
  );
}

export default HeaderBar;
