import React, { useState, useEffect } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TablePagination } from '@mui/material';
import NoDataFound from '../../Screens/NoDataFound/NoDataFound';
import './ReportingTable.css';
import { getSignedUrl, handlePrescriptionView } from '../../utility/helper';

const ReportingTable = ({ headers, appointments, tableCount, setPageData, pageData,setPageChange , pageChange }) => {
  const [page, setPage] = useState(pageData?.page || 0);
  const [rowsPerPage, setRowsPerPage] = useState(pageData?.rowsPerPage || 5);

  useEffect(() => {
    setPageData({ page, rowsPerPage });
  }, [page, rowsPerPage, setPageData]);

  useEffect(()=>{
    // console.log({pageChange})
    if(!pageChange){
      setRowsPerPage(5);
      setPage(0);
    }
  },[pageChange])

  const handleChangePage = (event, newPage) => {
    // console.log({event})
    setPageChange(true)
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const utcToIST = (utcTimestamp) => {
    const ISTOffset = 5.5 * 60 * 60 * 1000; 
    const date = new Date(utcTimestamp + ISTOffset);
    return date;
  };

  const formatDate = (timestamp)=>{
  return utcToIST(timestamp).toLocaleString('en-US', {
    day: '2-digit',
    month: 'short',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    timeZone:"UTC"
  });
}

  

  const handleRowRender = (key, value) => {
    if (key === 'timestamp') {
      return value ? formatDate(value) : 'N/A';
    } else if (key === 'prescriptionUrl') {
      return value ? (
        <div  className='reportingTable_prescriptionUrl' onClick={()=>handlePrescriptionView([value])}>
          <img src='https://img.pristyncare.com/amp_pictures%2FreportingViewIcon.png' alt='View' />
          View
        </div>
      ) : (
        'Pending'
      );
    } else if (key === 'amount') {
      return  `Rs. ${value}`
    } 
    else if (key === 'paymentStatus') {
      return value?.toLowerCase() ==='success' ? 'Paid' : 'Not Paid';
    } 
    else {
      return value ? value : 'N/A';
    }
  };

  const renderRows = () => {
   
    return appointments
      .map((appointment, index) => (
        <TableRow key={appointment.appointmentId}>
          <TableCell style={{ textAlign: 'center' }}>
            {page * rowsPerPage + index + 1}
          </TableCell>
          {headers?.map((header) => (
            <TableCell key={header.key} style={{ textAlign: 'center' }}>
              {handleRowRender(header.key, appointment[header.key])}
            </TableCell>
          ))}
        </TableRow>
      ));
  };

  return (
    <>
      <TableContainer className='reportingTable'>
        <Table style={{ boxShadow: 'none!important' }}>
          <TableHead>
            <TableRow>
              <TableCell style={{ textAlign: 'center' }}>S.No.</TableCell>
              {headers?.map((header) => (
                <TableCell key={header.key} style={{ textAlign: 'center' }}>
                  {header.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {appointments && appointments.length > 0 ? (
            <TableBody>{renderRows()}</TableBody>
          ) : null}
        </Table>
      </TableContainer>
      {appointments && appointments.length > 0 ? (
        <TablePagination
          component='div'
          count={tableCount}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          rowsPerPageOptions={[5, 10, 20]}
        />
      ) : (
        <NoDataFound type='reportingTable' />
      )}
    </>
  );
};

export default ReportingTable;
