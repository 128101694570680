import React, { useEffect, useRef, useState } from 'react';
import { TextField, MenuItem } from '@mui/material';
import Button from '@mui/material/Button';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import "./ScheduleAppointmentForm.css"
import { convertTimeToUTC, getCategoryAndDoctors, getDocFees, getDoctorSlots, getLeadDataByNumber, getLocalUser, getWalkInDetails } from '../../utility/helper';
import { Loading, Notify } from 'notiflix';
import { esES } from '@mui/x-date-pickers/locales';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import dayjs from 'dayjs';
import TimeSlots from '../../Components/TimeSlots/TimeSlots';
import NoSlots from '../../Components/NoSlots/NoSlots';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);
function ScheduleAppointmentForm({ setCreateApptObj ,setDisableButton , amountEditable}) {

  const [formData, setFormData] = useState({
    Mobile: '',
    FirstName: '',
    Disease: '',
    DocAssigned: '',
    category:'',
    docName: '',
    AppointmentDate: null,
    SurgeryType:'',
    walkinType: '',
    AppointmentStartTime: '',
    AppointmentEndTime: '',
    CashAmount:'',
    TotalAmount:''
  });

  const user = getLocalUser()
  useEffect(() => {
    // console.log("setCreateApptObj", { formData })
    if (formData) {
      setCreateApptObj(formData)
    }
  }, [formData])

  const [categoryList, setCategoryList] = useState([])
  const [combineList, setcombineList] = useState([])
  const [patientCount, setPatientCount] = useState(1);
  const [doctorList, setDoctorList] = useState([])
  const [mobileError, setMobileError] = useState('')
  const [docFees, setDocfees] = useState(undefined)
  const [walkinTypeState, setWalkinTypeState] = useState(null)
  const [showSlots, setShowSlots] = useState(false)
  const [slotData, setSlotData] = useState(false)
  const [slotDataArray,setSlotDataArray] = useState([])
  const [selectedDoctorSlot,setSelectedDoctorSlot] = useState(null)


  useEffect(() => {
    async function fetchDoctorFees() {
      try {
        setDocfees(undefined)
        setFormData((formData) => ({ 
          ...formData,
          CashAmount: undefined,
          TotalAmount: undefined
        }));
        const res = await getDocFees(formData.DocAssigned, formData.category)
        if (res != null) {
          const feeObj = res && res.length>0  ?res[0].consultationFees : null
          // console.log({feeObj})
          setFormData((formData) => ({ 
            ...formData,
            CashAmount: feeObj?.actualFee ? feeObj.actualFee : 0,
            TotalAmount: feeObj?.actualFee ? feeObj.actualFee : 0
          }));
          setCreateApptObj(formData)
          setDocfees(feeObj?.actualFee ? feeObj.actualFee : 0)
        } else {
          // console.log("else condition")
          setFormData((formData) => ({ 
            ...formData,
            CashAmount: 0,
            TotalAmount: 0
          }));
          setCreateApptObj(formData)
        }
      } catch (error) {
        setDocfees(undefined)
        setFormData((formData) => ({ 
          ...formData,
          CashAmount: undefined,
          TotalAmount: undefined
        }));

      }
    }
    if (formData && formData.category != '' && formData.DocAssigned != '') {
      fetchDoctorFees()
    }
  }, [formData.DocAssigned, formData.category])


  useEffect(() => {
    async function fetchCategoryAndDoctors() {
      try {
        const response = await getCategoryAndDoctors(user.clinicId);
        if (response != null) {
          const tempCategoryList = response ? response.map(item => { return { value: item.category, label: item.category } }) : []
          setCategoryList(tempCategoryList)
          setcombineList(response)
        }
      } catch (error) {
        console.error(error);
      }
    }
    fetchCategoryAndDoctors();

  }, [])

  // Debounce function 
  let timeoutId;
  const debounce = (func, delay) => {
    return function (...args) {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };
  const handleDebounce = debounce(() => fetchPatientName(), 1000)

  
  const handleDateChange = async(date) => {
    setSelectedDoctorSlot(null)
    setFormData((formData) => ({
      ...formData,
      AppointmentDate : dayjs(date).format('YYYY-MM-DD')
    }))
  };

  async function fetchPatientName() {
    // console.log("fetchPatientName", formData.Mobile)
    try {
      const res = await getLeadDataByNumber(formData.Mobile)
      if (res != null) {
        const patientName = await res[0].patientName
        setFormData((formData) => ({
          ...formData,
          ['FirstName']: patientName,
        }));
        setCreateApptObj(formData)
      }
    } catch (error) {

    }
  }
  const validateMobileNumber = (mobileNumber) => {
    if (mobileNumber !== '') {
      if (mobileNumber.length > 10) {
        setMobileError('Mobile number cannot exceed 10 digits');
        return false;
      } else if (mobileNumber.length < 10) {
        setMobileError('Mobile number cannot be less than 10 digits');
        return false;
      } else if (!/^[6-9]/.test(mobileNumber)) {
        setMobileError('Mobile number can only start with 6, 7, 8, or 9');
        return false;
      } else {
        setMobileError('');
        return true;
      }
    } else {
      setMobileError('');
      return true;
    }
  }

  useEffect(() => {
    if (formData && formData.Mobile != '' && formData.Mobile.length === 10 && validateMobileNumber(formData.Mobile)) {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(() => {
        fetchPatientName();
      }, 1000);
    }
  }, [formData.Mobile])


  const handleChange = async (event) => {
    if (event.target.name === 'Mobile' && event.target.value != '') {
      validateMobileNumber(event.target.value)
      setFormData((formData) => ({
        ...formData,
        [event.target.name]: event.target.value.slice(0, 10),
      }))
    }
    else if (event.target.name === 'category') {
      setDoctorList([])
      setSlotDataArray([])
      setSlotData(false)
      // setDisableButton(true)
      setSelectedDoctorSlot(null)
      setShowSlots(false)
      const selectedData = combineList.filter((item, index) => {
        if (item.category === event.target.value) {
          return item.doctors
        }
      })
      if (selectedData && selectedData.length > 0) {
        const tempDoctorList = selectedData[0].doctors.map((doc) => {
          if (doc.doctorId) {
            return {
              value: doc.doctorId,
              label: doc.doctorName
            }
          }
        })
        setTimeout(() => {
          tempDoctorList && setDoctorList(tempDoctorList)
        }, 0)
      }
      setFormData((formData) => ({
        ...formData,
        ['DocAssigned']: '',
        ['docName']: '',
        [event.target.name]: event.target.value,
        "Disease": event.target.value,
        "SurgeryType": selectedData[0].surgeryType ? selectedData[0].surgeryType : '',
      }));
    }
    else if (event.target.name === 'doctor') {
      setSelectedDoctorSlot(null)
      const selectedDoctor = doctorList.find((doc) => doc.value === event.target.value);
      setFormData((formData) => ({
        ...formData,
        ['DocAssigned']: event.target.value,
        ['docName']: selectedDoctor.label
      }));
    }
    else if(event.target.name==='DoctorFee'){
      setFormData((formData) => ({
        ...formData,
        CashAmount: Number(event.target.value),
        TotalAmount: Number(event.target.value)
      }));
    }
    else {
      setFormData((formData) => ({
        ...formData,
        [event.target.name]: event.target.value,
      }));
    }
    setCreateApptObj(formData)
  };
  

  useEffect(() => {
    setWalkinTypeState(null)
    setFormData((formData) => ({ 
      ...formData,
      ['walkinType']: null,
    }));
    async function getAppointmentType() {
      const apptDetails = await getWalkInDetails(formData.Mobile, user.clinicId, formData.DocAssigned, formData.SurgeryType)
      if (!apptDetails)  {
        Notify.failure("Please Choose different Category");
        setWalkinTypeState(null)
        setFormData((formData) => ({ 
          ...formData,
          ['walkinType']: null,
        }));
        return
      }
      
      const startTimeUTC = convertTimeToUTC(dayjs(formData.AppointmentDate).format('YYYY-MM-DD'),selectedDoctorSlot?.start);
      const endTimeUTC = convertTimeToUTC(dayjs(formData.AppointmentDate).format('YYYY-MM-DD'),selectedDoctorSlot?.end);
      
      setFormData((formData) => ({ 
        ...formData,
        ['walkinType']: apptDetails.walkinType ? apptDetails.walkinType : "new",
        ["AppointmentStartTime"]: startTimeUTC,
        ["AppointmentEndTime"]: endTimeUTC,
      }));
      setCreateApptObj(formData)
      setWalkinTypeState(apptDetails.walkinType ? apptDetails.walkinType : "new")
    }
    // console.log({formData})
    if (validateMobileNumber(formData.Mobile) && formData.DocAssigned != '' && formData.category != '' && formData.AppointmentDate != null && selectedDoctorSlot!=null) {
      getAppointmentType()
    }
    async function fetchSlots(requestData) {
      setSelectedDoctorSlot(null)
      const slotRes  = await getDoctorSlots(requestData)
      Loading.remove()
      if(slotRes && slotRes.length>0){
          // setDisableButton(false)
          setSlotDataArray(slotRes)
          setSlotData(true)
      }
      else{
        // setDisableButton(true)
        setSlotData(false)
      }
      setShowSlots(true)
      return slotRes
    }
    
    if(formData.DocAssigned!='' && formData.category!=='' && formData.AppointmentDate != null && selectedDoctorSlot===null){
      Loading.circle()
      if(formData.AppointmentDate>=dayjs().format('YYYY-MM-DD')){
        const requestData = {
          date: dayjs(formData.AppointmentDate).format('YYYY-MM-DD'),
          appointmentType: "OPD",
          hospitalId: user.clinicId,
          opdType:"Consultation",
          category: formData.category,
          objectId: formData.DocAssigned, 
          surgery: formData.SurgeryType
        };
        console.log({requestData})
        fetchSlots(requestData)  
      }
      else{
        Loading.remove()
        setSlotDataArray([])
        setSlotData(false)
        setSelectedDoctorSlot(null)
        Notify.failure('Please change the date')
      }
    }
    
  }, [formData.DocAssigned, formData.category ,formData.AppointmentDate,selectedDoctorSlot])

  useEffect(() => {
    if (selectedDoctorSlot != null && formData.Mobile !== '' && validateMobileNumber(formData.Mobile)) {
      setDisableButton(false);
    } else {
      setDisableButton(true);
    }
  }, [selectedDoctorSlot, formData.Mobile]);
  

  return (
    <form>
      <div className="scheduleAppnt_inputRow">
        <div className="scheduleAppnt_inputContainer">
          <TextField
            size="small"
            label="Mobile Number"
            name="Mobile"
            value={formData.Mobile}
            onChange={handleChange}
            fullWidth
            margin="normal"
            type="number"
          />
          {mobileError && <div className="error-message">{mobileError}</div>}
        </div>
        <div className="scheduleAppnt_inputContainer">
          <TextField
            size="small"
            label="Primary Patient Name"
            name="FirstName"
            value={formData.FirstName}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
        </div>
      </div>
      <div className="scheduleAppnt_inputRow">
        <div className="scheduleAppnt_inputContainer">
          <TextField
            size="small"
            select
            label="Select Procedure"
            name="category"
            value={formData.category}
            onChange={handleChange}
            fullWidth
            margin="normal"
          >
            {categoryList.map((item, index) => (
              <MenuItem key={index} value={item.value}>
                {item.label}
              </MenuItem>
            ))}
          </TextField>
        </div>
        <div className="scheduleAppnt_inputContainer">
          {/* {formData.category && doctorList.length ? ( */}
          <TextField
            size="small"
            select
            label="Select Doctor"
            name="doctor"
            value={formData.DocAssigned}
            onChange={handleChange}
            fullWidth
            margin="normal"
          >
            {doctorList.map((doc, index) => (
              <MenuItem key={index} value={doc.value}>
                {doc.label}
              </MenuItem>
            ))}
          </TextField>
          {/* ) : null} */}
        </div>
        <div className="scheduleAppnt_inputContainer">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              onChange={(e) => handleDateChange(e)}
              minDate={dayjs()}
              className='scheduleAppnt_datePicker'
              // disabled={pendingAppointmentFlag}
              format="DD-MM-YYYY"
              slotProps={{ popper: { placement: 'right' } }}
            />
          </LocalizationProvider>
        </div>
      </div>
      {formData.category!='' && docFees !== undefined && formData.DocAssigned!='' ? 
      <div className='scheduleAppnt_ConsultaionFee'> 
        <p>Consultation Fee :</p>
        <TextField
            size="small"
            label="Amount"
            name="DoctorFee"
            defaultValue={formData.TotalAmount}
            onChange={handleChange}
            margin="normal"
            type="number"
            style={{width:'30%'}}
            inputProps={{ min: 0 }}
            disabled={!amountEditable}
          />
      </div> : null}
      {showSlots ?
        <>
          {
            slotData ?
              <>
                <p style={{ color: '#4A4A4A', fontSize: '14px', fontWeight: '600', paddingLeft: '5px', margin: '5px 0' }}>Time Slots</p>
                <TimeSlots data={slotDataArray} setSelectedDoctorSlot={setSelectedDoctorSlot}/>
              </>
              :
              <NoSlots />
          }

        </>
        :
        null
      }
    </form>

  );

}

export default ScheduleAppointmentForm