import React from 'react'
import "./Reporting.css"
import ReportingFilters from '../../Components/ReportingFilters/ReportingFilters'
import HomeIcon from '@mui/icons-material/Home';

function Reporting() {
  const handleHome = ()=>{
    window.location.href = '/home'
  }
  return (
    <>
        <div className='reporting_header'>
          <HomeIcon sx={{ marginRight: '3px' ,cursor:'pointer', color:'#0071BC' }} onClick={handleHome}/>
          |
          <p>Patient Report</p>
        </div>
        <div className='dividerLine'></div>
        <ReportingFilters/>

    </>
  )
}

export default Reporting