import React from 'react';
import './NoDataFound.css'; 

const NoDataFound = ({type=''}) => {
  return (
    <div className="no-data-container">
      <div className="no-data-icon">📄</div>
      <div className="no-data-message">No Appointments Found</div>
      {type==='patientListing' && <div className="no-data-message">Please Change the Date</div>}
    </div>
  );
};

export default NoDataFound;