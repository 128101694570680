import { Button, Checkbox, FormControlLabel, MenuItem, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import "./InvoiceForm.css"
import { fetchDiseaseByCategory, formatCurrentTime, getLocalUser, uploadFiles } from '../../utility/helper';
import { DateRange } from '@mui/icons-material';
import { Notify } from 'notiflix';


function InvoiceForm({ data, type, setFilledInvoiceData ,amountEditable }) {
  const [checked, setChecked] = useState(false)
  const [patientEmail, setPatientEmail] = useState('')
  const [remarks, setRemarks] = useState('')
  const [paymentModes, setPaymentModes] = useState([]);
  const [procedureList, setProcedureList] = useState(null);
  const [selectedProcedure, setSelectedProcedure] = useState('')
  // const [additionalCharges,setAdditionalCharges] = useState('')
  const [notification, setNotification] = useState('');
  const [netPayableAmount, setNetPayableAmount] = useState(type === 'invoice' ? data.amountToCollect : 0)
  const user = getLocalUser()
  const [consultationFee, setConsultaionFee] = useState(data.cashAmount || 0)
  const [invoicePayload, setInvoicePayload] = useState({
    "allPartPayments": [],
    "appointmentDetails": [
      {
        "actualAmountCollected": 0,
        "appointmentId": data._id || data_appId,
        "clinicId": user.clinicId,
        "consultationFee": data.cashAmount,
        "consumableAmount": 0,
        "isconsumableInvoice": true,
        "markAsArrival": true,
        "mobileNumber": data.mobile,
        "partPayments": [],
        "patientName": data.patientName,
        "paymentCapturedBy": "Pristyn",
        "totalAmount": data.TotalAmount || 0,
        "remark": [],
        "amountToCollect": data.amountToCollect
      }
    ]
  })
  const paymentModeList = [
    {
      value: 'Cash',
      label: 'Cash'
    },
    {
      value: 'QR Code',
      label: 'QR Code'
    }, {
      value: 'Card',
      label: 'Card'
    }
  ]

  const updateAllPartPayments = (updatedPaymentModes) => {
    setInvoicePayload((prevPayload) => ({
      ...prevPayload,
      allPartPayments: updatedPaymentModes
    }));
  };

  let CATAGORY_CONSTANTS = {
    "GYNAECOLOGY": "Gynaecology",
    "GENERAL GYNAECOLOGY": "Gynaecology",
    "IVF": "IVF",
    "AESTHETICS": "Aesthetics",
    "PROCTOLOGY": "Proctology"
  }

  useEffect(() => {
    console.log("category", data?.category)
    let categoryForMapping = CATAGORY_CONSTANTS[data?.category?.toUpperCase()]

    categoryForMapping && fetchDiseaseByCategory(categoryForMapping).then((res) => {
      setProcedureList(res.data || [])
    })
  }, [data?.category])

  useEffect(() => {
    if (invoicePayload) {
      setFilledInvoiceData(invoicePayload)
    }
  }, [invoicePayload])

  const handleFilledInvoiceData = (newData) => {
    setInvoicePayload((prevPayload) => ({
      ...prevPayload,
      appointmentDetails: prevPayload.appointmentDetails.map((appointment) => ({
        ...appointment,
        ...newData
      }))
    }));
  };


  const now = new Date();
  const utcString = now.toISOString();
  // const istString = new Date().replace(/\/|, /g, '-').replace(' ', 'T') + "+05:30";

  const handleAddPaymentMode = () => {
    const newPaymentMode = {
      paymentMode: 'Cash',
      amount: 0,
      timestamnp: utcString,
      uploadedFile: null
    };
    const updatedPaymentModes = [...paymentModes, newPaymentMode];
    setPaymentModes(updatedPaymentModes);
    updateAllPartPayments(updatedPaymentModes);
  };

  const handlePaymentChange = (index, field, value) => {
    setPaymentModes(prevPaymentModes => {
      const updatedPaymentModes = [...prevPaymentModes];
      updatedPaymentModes[index][field] = value;
      updateAllPartPayments(updatedPaymentModes);
      return updatedPaymentModes;
    });
  };


  const handleRemovePaymentMode = (index) => {
    setPaymentModes(prevPaymentModes => {
      const updatedPaymentModes = prevPaymentModes.filter((_, i) => i !== index);
      updateAllPartPayments(updatedPaymentModes);
      return updatedPaymentModes;
    });
  };


  const handleFileChange = async (index, event) => {
    const file = event.target.files[0];
    if (file) {
      const maxSizeInMB = 5;
      const maxSizeInBytes = maxSizeInMB * 1024 * 1024;

      if (file.size > maxSizeInBytes) {
        Notify.failure(`File size should be less than ${maxSizeInMB} MB`);
        return;
      }
      const response = await uploadFiles(file, data._id, paymentModes[index].paymentMode);
      if (response) {
        setPaymentModes(prevPaymentModes => {
          const updatedPaymentModes = [...prevPaymentModes];
          updatedPaymentModes[index].uploadedFile = file;
          return updatedPaymentModes;
        });
      }
      else {
        Notify.failure('Please try uploading again')
      }
    }
  };

  const handleChange = () => {
    setChecked(!checked)
  }

  const handleDataChange = (event) => {
    if (event.target.name === 'patientEmail') {
      const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
      if (emailPattern.test(event.target.value)) {
        setNotification('Valid email address');
      } else {
        setNotification('Invalid email address');
      }
      setPatientEmail(event.target.value);
      handleFilledInvoiceData({ patientEmail: event.target.value });
    }
    else if (event.target.name === 'remarks') {
      setRemarks(event.target.value)
      handleFilledInvoiceData({ remark: [event.target.value] })
    }
    else if (event.target.name === 'procedure') {
      setSelectedProcedure(event.target.value)
      handleFilledInvoiceData({ procedureType: event.target.value })
    }
    else if (event.target.name === 'additionalCharges') {
      setNetPayableAmount(event.target.value)
      handleFilledInvoiceData({ consumableAmount: Number(event.target.value) })
    }
    else if (event.target.name === 'netPayableAmount') {
      setNetPayableAmount(event.target.value)
      setConsultaionFee(event.target.value)
      handleFilledInvoiceData({ amountToCollect: Number(event.target.value) })
      handleFilledInvoiceData({ consultationFee: Number(event.target.value) })
      handleFilledInvoiceData({ totalAmount: Number(event.target.value) })
    }
  }

  return (
    <div className='invoiceForm_container'>
      <div className='invoiceForm_patientIds'>
        <p>Patient ID - {data.patientId}</p>
        <p>Appointment ID - {data._id}</p>
      </div>
      <div className='invoiceForm_patientDetails'>
        <p>Patient Name - <span style={{ fontWeight: '600', fontSize: '20px', color: 'inherit' }}>{data.patientName}</span></p>
        <FormControlLabel
          label="Include Patient Email"
          control={<Checkbox checked={checked} onChange={handleChange} />}
        />
        {
          checked ?
            <>
              <TextField
                size="small"
                label="Patient Email"
                name="patientEmail"
                value={patientEmail}
                onChange={handleDataChange}
                fullWidth
                margin="normal"
              />
            </> : null
        }
        {notification && <div style={{ color: `${notification.includes('Invalid') ? 'red' : 'green'}` }}>{notification}</div>}
      </div>
      <div className='invoiceForm_feeBreakUpContainer'>
        <p className='invoiceForm_feeBreakUp_title'>Break Up</p>
        <div className='invoiceForm_feeBreakUp'>
          {type === 'invoice' ?
            <>
              <div>
                <p>Consultation Fee</p>
                <p className='invoiceForm_feeAmount'>₹ {consultationFee}</p>
              </div>
              <div>
                <p>Advance collected at GHV</p>
                <p className='invoiceForm_feeAmount'>₹ {data.totalAdvancePayment || 0}</p>
              </div>
              <div>
                <p>Payment Status</p>
                <p style={{ color: '#4A4A4A', fontWeight: '600' }}>{'Pending'}</p>
              </div>
            </>
            :
            <>
              <div>
                <p>Additional Charges</p>
                <TextField
                  size="small"
                  label="additionalCharges"
                  name="additionalCharges"
                  value={netPayableAmount}
                  onChange={handleDataChange}
                />
              </div>
            </>
          }
        </div>
        <TextField
          size="small"
          label="Remarks"
          name="remarks"
          value={remarks}
          onChange={handleDataChange}
          fullWidth
          margin="normal"
        />
      </div>
      <div className='invoiceForm_PaymentContainer'>
        <div className='invoiceForm_procedure'>
          <div className='netPayableAmount_Container'>
            <p>Net Payable Amount:</p>
            <TextField
              size="small"
              label="Amount"
              name="netPayableAmount"
              // value={netPayableAmount}
              onChange={handleDataChange}
              style={{ width: '20%' }}
              defaultValue={netPayableAmount}
              type="number"
              inputProps={{ min: 0 }}
              disabled={!amountEditable}
            />
            {/* <span style={{ color: '#119645' }}> 
            ₹{netPayableAmount}</span> */}
          </div>
          {type === 'invoice' && procedureList && <TextField
            size="small"
            select
            label="Select Procedure"
            name="procedure"
            value={selectedProcedure}
            onChange={handleDataChange}
            fullWidth
            margin="normal"
          >
            {procedureList.map((doc, index) => (
              <MenuItem key={index} value={doc}>
                {doc}
              </MenuItem>
            ))}
          </TextField>}
        </div>
        <div className='invoiceForm_procedure' style={{ marginTop: '1rem' }}>
          <p>Payment Mode</p>
          <Button variant='contained' onClick={handleAddPaymentMode}>Add Payment Mode</Button>
        </div>
        {paymentModes.map((paymentMode, index) => (
          <div key={index} className='invoiceForm_paymentSection'>
            <div className='invoiceForm_modeSection'>
              <TextField
                size="small"
                select
                label="Payment Mode"
                name="paymentMode"
                value={paymentMode.paymentMode}
                onChange={(e) => handlePaymentChange(index, 'paymentMode', e.target.value)}
                className='invoiceForm_paymentSelect'
                disabled={paymentMode.paymentMode === 'Cash' ? paymentMode.amount : (paymentMode.uploadedFile && paymentMode.amount)}
              >
                {paymentModeList.map((mode, index) => (
                  <MenuItem key={index} value={mode.value}>
                    {mode.label}
                  </MenuItem>
                ))}
              </TextField>
              {
                paymentMode.paymentMode != 'Cash' ? <label style={{ display: 'flex', alignItems: 'center' }}>
                  <input
                    type="file"
                    accept="image/*"
                    onChange={(e) => handleFileChange(index, e)}
                    style={{ display: 'none' }}
                  />
                  <img src="https://img.pristyncare.com/uploadImage_receptonistApp.png" />
                  {paymentMode.uploadedFile && (
                    <span style={{ fontSize: '12px' }}>Uploaded File: {paymentMode.uploadedFile.name}</span>
                  )}
                </label> : null
              }
            </div>
            <div className='invoiceForm_paymentModeSection'>
              <TextField
                size="small"
                label="Amount"
                name="amount"
                value={paymentMode.amount}
                onChange={(e) => handlePaymentChange(index, 'amount', e.target.value)}
                placeholder="₹"
                type='number'
              />
              <RemoveCircleOutlineIcon style={{ color: 'red' }} onClick={() => handleRemovePaymentMode(index)} />
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default InvoiceForm