import React, { Suspense, useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import HeaderBar from './Components/HeaderBar/HeaderBar';
import PaitentListing from './Screens/PaitentListing/PaitentListing';
// import AuthScreen from ""
import './App.css';
import { isTokenValid } from './utility/helper';
import { Loading } from 'notiflix';
import Reporting from './Screens/Reporting/Reporting';
const AuthScreen = React.lazy(() => import('./commonComponents/AuthScreen'));


// src/App.js

const App = () => {
  const [authenticated, setAuthenticated] = useState(false);
  const [loadingForCheckToken, setLoaderForTokenChange] = useState(true)
  // const navigate = useNavigate();

  useEffect(() => {

    (async()=>{
      const tokenRes =await isTokenValid();
      if(!tokenRes) {
        localStorage.clear()
      }    
      setLoaderForTokenChange(false)
    })()
  }, [])

  useEffect(() => {
    if (localStorage.getItem('user')) {
      setAuthenticated(true)
    }
  }, [])

  useEffect(() => {
    loadingForCheckToken ? Loading.circle() : Loading.remove()

  }, [loadingForCheckToken])

  return (
    <>
      <HeaderBar/>
      <Router>
        <Routes>
          <Route path="/" element={<Suspense fallback={<div>Loading...</div>}>
            <AuthScreen setAuthenticated={setAuthenticated} />
          </Suspense>} />
          <Route
            path="/home"
            element={loadingForCheckToken ? <div>Loader...</div> : authenticated  ? <>
              <PaitentListing />
            </> : <Navigate to="/" />}
          />
          <Route
            path="/report"
            element={loadingForCheckToken ? <div>Loader...</div> : authenticated  ? <>
              <Reporting />
            </> : <Navigate to="/" />}
          />
        </Routes>
      </Router>
    </>
  );
};

export default App;
