import React, { useEffect, useState } from 'react'
import { FormControl, RadioGroup, FormControlLabel, Radio, FormLabel } from '@mui/material';
import ArrivalStatusForm from '../../Elements/ArrivalStatusForm/ArrivalStatusForm';
import { formatCurrentTime } from '../../utility/helper';

function ArrivalStatus({data,setArrivalFormData}) {
  const [selectedValue, setSelectedValue] = useState('');
  const [showForm, setShowForm] = useState(false);
  const [formData,setFormData] = useState(null);

  useEffect(() => {
    if (data) {
      let newFormData;
      let now = new Date;
      let utc_timestamp = Date.UTC(now.getUTCFullYear(),now.getUTCMonth(), now.getUTCDate() , 
      now.getUTCHours(), now.getUTCMinutes(), now.getUTCSeconds(), now.getUTCMilliseconds());
      const currentTime = utc_timestamp;
      if(formData!=null){
        newFormData = {
          ...formData,
           appId: data._id || data.appId,
           receptionistStatus: selectedValue ? selectedValue : null,
           enableNotArrivedCard: selectedValue === "notArrived"? true : false,
           leadId: data.leadId,
           recStatus: "InProgress",
           PatientArrivalTime:selectedValue === "arrived" ?  currentTime : null,
         };
      }else{
        const currentTime = utc_timestamp;

        const recMeasure = {
          feet: "",
          inches: "",
          maxBloodPressure: "",
          minBloodPressure: "",
          weight:""
        };
    
        const formData = {
          numberOfAttendants:"",
          recMeasure,
          PatientArrivalTime:selectedValue === "arrived" ?  currentTime : null,
          address:"",
        };

        newFormData = {
          ...formData,
          appId: data._id || data.appId,
           receptionistStatus: selectedValue  ? selectedValue : null,
           enableNotArrivedCard: selectedValue === "notArrived"? true : false,
           leadId: data.leadId,
           recStatus: "InProgress"
         };
      }
     
      setArrivalFormData(newFormData);
    }
  }, [formData, data, selectedValue]);


  const handleChange = (event) => {
    setSelectedValue(event.target.value);
    if (event.target.value === 'arrived') {
      setShowForm(true);
    } else {
      setShowForm(false);
      setFormData(null)
    }
  };

  return (
    <div style={{overflowY:'auto'}}>
      <FormControl>
        <RadioGroup value={selectedValue} onChange={handleChange}>
          <FormControlLabel value="arrived" control={<Radio />} label="Arrived" />
          {showForm && (
            <ArrivalStatusForm setFormData={setFormData}/>
          )}
          <FormControlLabel value="notArrived" control={<Radio />} label="Not Arrived" />
          <FormControlLabel value="DoctorNotAvailable" control={<Radio />} label="Arrived, but doctor not available" />
        </RadioGroup>
      </FormControl>
    </div>
  )
}

export default ArrivalStatus