import React, { useEffect, useState } from 'react'
import { Button, Grid, Typography, Box, Stack } from '@mui/material';
import { ImageListItem} from '@mui/material';
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';
import { allowedImageTypes } from '../../utility/helper';
import { Notify } from 'notiflix';
const Item = styled(ImageListItem)(({ theme }) => ({
  margin: theme.spacing(1),
  width: "30%",
  height: "150px !important",
  '& img': {
    borderRadius: "10px",
    objectFit: "contain",
    cursor: "pointer",
    position: 'relative',
  }
}));

function PrescriptionUploadForm({data,setPrescriptionData}) {
  const [images, setImages] = useState([]);
  const [formData,setFormData] = useState([])

  useEffect(()=>{
    if(formData){
      setPrescriptionData(formData)
    }
  },[formData])

  const handleImageUpload = (e) => {
    const files = e.target.files;
    const validFiles = [];
    if(files && files.length>3){
      setFormData([])
      Notify.failure('Up to 3 images can be added')
      return
    }
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const fileType = file.type;
  
      if (!allowedImageTypes.includes(fileType)) {
        alert('Only image files are allowed!');
        continue;
      }
  
      validFiles.push(file);
    }
  
    if (validFiles.length > 0) {
      const newFormData = [...formData, ...validFiles];
      const newImages = [...images];
  
      validFiles.forEach((file) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          newImages.push(reader.result);
          setImages(newImages);
        };
        reader.readAsDataURL(file);
      });
      setFormData(newFormData);
    }
  };

  const handleDeleteImage = (index) => {
    if (index < 0 || index >= images.length || index >= formData.length) return;
    const newImages = images.filter((_, i) => i !== index);
    const newFormData = formData.filter((_, i) => i !== index);
    setImages(newImages);
    setFormData(newFormData);
  };

  console.log({formData})

  return (
    <Grid container spacing={2} justifyContent="center" alignItems="center">
      <Grid item xs={12}>
        <Typography variant="body1" sx={{color:'#9A9A9A',fontWeight:'bold'}}>
            Up to 3 images can be added
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
          {images.map((image, index) => (
            <Item key={index}>
              <img src={image}/>
              <img  src="https://img.pristyncare.com/amp_pictures%2FcloseIconRWA.png"
                style={{
                  position: 'absolute',
                  top: '-10px',
                  right: '-10px',
                  cursor: 'pointer',
                  height:'25px',
                  width:'25px',
                  objectFit:'contain'
                }}
                onClick={() => handleDeleteImage(index)}
              />
            </Item>
          ))}
          {images.length < 3 && (
            <Stack sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <label htmlFor="upload-photo">
                <Button  component="span">
                    <img src="https://img.pristyncare.com/amp_pictures%2FaddPhotoReciptionistWebApp.png"/>
                </Button>
                <input
                  id="upload-photo"
                  type="file"
                  hidden
                  multiple
                  onChange={handleImageUpload}
                />
              </label>
            </Stack>
          )}
        </Box>
      </Grid>
    </Grid>
  );
}

export default PrescriptionUploadForm