import React, { useEffect, useState } from 'react'
import { Container, TextField, MenuItem, Grid } from '@mui/material';
import { formatCurrentTime } from '../../utility/helper';

const heights = Array.from({ length: 7 }, (_, i) => i+1); 
const inches = Array.from({ length: 12 }, (_, i) => i); 
const bloodMaxPressureValues = Array.from({ length: 141 }, (_, i) => i + 60); 
const bloodMinPressureValues = Array.from({ length: 121 }, (_, i) => i ); 


function ArrivalStatusForm({setFormData}) {
  const [numberOfAttendants, setNumberOfAttendants] = useState('');
  const [weight, setWeight] = useState('');
  const [heightFeet, setHeightFeet] = useState('');
  const [heightInches, setHeightInches] = useState('');
  const [bpMax, setBpMax] = useState('');
  const [bpMin, setBpMin] = useState('');
  const [address, setAddress] = useState('');

  useEffect(() => {
    const recMeasure = {
      weight,
      feet: `${heightFeet}`,
      inches: `${heightInches}`,
      maxBloodPressure: `${bpMax}`,
      minBloodPressure: `${bpMin}`,
    };

    const formData = {
      numberOfAttendants,
      recMeasure,
      address,
    };

    setFormData(formData); 
  }, [numberOfAttendants, weight, heightFeet, heightInches, bpMax, bpMin, address]);
  

  return (
    <Container maxWidth="sm" sx={{margin:'1rem 0'}}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField
            size="small"
            label="Number of Attendant (1-9)"
            type="number"
            fullWidth
            value={numberOfAttendants}
            onChange={(e) => setNumberOfAttendants(e.target.value)}
            InputLabelProps={{ style: { fontSize: 14 } }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            size="small"
            label="Weight(Kg) (5-200)"
            type="number"
            fullWidth
            value={weight}
            onChange={(e) => setWeight(e.target.value)}
            InputLabelProps={{ style: { fontSize: 14 } }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            size="small"
            select
            label="Height (Ft)"
            fullWidth
            value={heightFeet}
            onChange={(e) => setHeightFeet(e.target.value)}
            InputLabelProps={{ style: { fontSize: 14 } }}
          >
            {heights.map((feet) => (
              <MenuItem key={feet} value={feet}>
                {feet}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={6}>
          <TextField
            size="small"
            select
            label="Height(Inches)"
            fullWidth
            value={heightInches}
            onChange={(e) => setHeightInches(e.target.value)}
            InputLabelProps={{ style: { fontSize: 14 } }}
          >
            {inches.map((inch) => (
              <MenuItem key={inch} value={inch}>
                {inch}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={6}>
          <TextField
            size="small"
            select
            label="Blood Pressure (Max)"
            fullWidth
            value={bpMax}
            onChange={(e) => setBpMax(e.target.value)}
            InputLabelProps={{ style: { fontSize: 14 } }}
          >
            {bloodMaxPressureValues.map((value) => (
              <MenuItem key={value} value={value}>
                {value}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={6}>
          <TextField
            size="small"
            select
            label="Blood Pressure (Min)"
            fullWidth
            value={bpMin}
            onChange={(e) => setBpMin(e.target.value)}
            InputLabelProps={{ style: { fontSize: 14 } }}
          >
            {bloodMinPressureValues.map((value) => (
              <MenuItem key={value} value={value}>
                {value}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12}>
          <TextField
            size="small"
            label="Address"
            fullWidth
            value={address}
            onChange={(e) => setAddress(e.target.value)}
            InputLabelProps={{ style: { fontSize: 14 } }}
          />
        </Grid>
      </Grid>
    </Container>
  )
}

export default ArrivalStatusForm