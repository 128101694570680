import { Notify } from "notiflix";

export const allowedImageTypes = [
  'image/jpeg',
  'image/png',
  'image/gif',
  'image/bmp',
  'image/tiff',
  'image/svg+xml',
  'image/webp',
  'image/heic',
  'image/avif',
  'image/jp2',
];

export async function getSignedUrl(urls) {
  try {
    const response = await fetch(`${process.env.REACT_APP_NODE_SIGNED_BASE_URL}/EMR/getSignedUrl`, {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + getLocalUser().access_token,
      },
      body: JSON.stringify({
        "urls": urls
      })
    });

    return await response.json();
  } catch (error) {
    console.error(error);
    return null;
  }
}

export function getLocalUser() {
  const user = JSON.parse(localStorage.getItem('user'));
  return user
}

export function handleUserLogOut() {
  localStorage.clear();
  window.location.href = '/home'
}

export async function APIFetch(url, customHeader = {}, CUSTOM_API_URL) {
  try {
    const response = await fetch(`${CUSTOM_API_URL || process.env.REACT_APP_JAVA_BASE_URL_COMMON}/${url}`, {
      mode: "cors",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: "bearer " + getLocalUser().access_token,
        ...customHeader
      }
    }
    );
    const data = await response.json()
    if (response.status === 401 || response.status === 403) {
      localStorage.clear()
      window.location.href = '/'
    }
    else if (data.status === "Success") {
      return data.result ? data.result : data.description === "No data found" ? null : data;
    } else {
      return null
    }
  } catch (error) {
    console.error(error);
  }
}

export async function APIPost(url, bodyData, customHeader = {}, CUSTOM_API_URL) {

  try {
    const response = await fetch(`${CUSTOM_API_URL || process.env.REACT_APP_JAVA_BASE_URL_COMMON}/${url}`, {
      method: 'POST',
      body: JSON.stringify(bodyData),
      headers: {
        'Content-Type': 'application/json',
        Authorization: "bearer " + getLocalUser().access_token,
        ...customHeader
      }
    });
    const data = await response.json()
    if (response.status === 401 || response.status === 403) {
      localStorage.clear()
      window.location.href = '/'
    }
    else if (data.status === "Success" || data.status === "1") {
      return data.result ? data.result : data.description === "No data found" ? null : data;
    } else {
      return null
    }
  } catch (error) {
    console.error(error);
  }
}

export async function fetchPendingApptData(date, clientId) {
  try {
    const response = await APIFetch(`api/receptionistApp/appointments/${clientId}/pendingAppointments?date=${date}&status=pending`);
    return response;
  } catch (error) {
    console.error(error);
    return null;
  }
}

export async function fetchTodaysApptData(date, clinicId, doctorId) {
  try {
    const response = await APIPost(`api/receptionistApp/getAppointments`, {
      "clinicId": clinicId,
      "doctorId": doctorId,
      "date": date
    });
    return response;
  } catch (error) {
    console.error(error);
    return null;
  }
}

export async function fetchDoctorList(clinicId, date) {
  try {
    const response = await APIPost(`api/receptionistApp/docList`, {
      "clinicId": clinicId,
      "date": date
    })
    return response;
  }
  catch (error) {
    console.error(error);
    return null;
  }
}

export async function submitArrivalStatus(raw) {
  try {
    const response = await APIPost('api/receptionistApp/updateArrivalStatus', raw)
    return response
  } catch (error) {
    console.error(error)
    return null
  }
}

export async function submitBookAppointment(raw) {
  try {
    const response = await APIPost('api/V1/createWalkinAppointment ', raw, {}, process.env.REACT_APP_PROXY_COMMON_URL)
    return response
  } catch (error) {
    console.error(error)
    return null
  }
}

export async function getCategoryAndDoctors(clinicId) {
  try {
    const response = await APIPost('api/getCategoriesAndDoctors', {
      "clinicId": clinicId
    }
    )
    return response
  } catch (error) {
    console.error(error)
    return null
  }
}

export async function getLeadDataByNumber(mobileNumber) {
  try {
    const response = await APIPost('api/receptionistApp/getLeadDetailsByLeadNumber', {
      "mobileNumber": mobileNumber
    }, {}, process.env.REACT_APP_PROXY_COMMON_URL)
    return response
  } catch (error) {
    console.error(error)
    return null
  }
}


export async function getMobileHash(mobileNumber) {
  try {
    const response = await APIPost('des/hash-mobile', {
      "mobileNumber": mobileNumber
    }
    )
    return response
  } catch (error) {
    console.error(error)
    return null
  }
}

export async function getWalkInDetails(mobile, clinicId, docId, surgeryType) {
  try {
    const response = await APIPost('api/v1/receptionistApp/getWalkInDetails', {
      "Mobile": mobile,
      "SurgeryType": surgeryType,
      "DocAssigned": docId,
      "Hospital_clinic": {
        "id": clinicId
      }
    }
    )
    return response
  } catch (error) {
    console.error(error)
    return null
  }
}

export async function getDocFees(docId, cat) {
  try {
    const response = await APIPost('', {
      "docObjectId": [docId],
      "category": cat,
      "consultationType": "new"
    },
      {},
      'https://api.pristyncare.com/exposed/v2/doctorFees'
    )
    return response
  } catch (error) {
    console.error(error)
    return null
  }
}

export function formatCurrentTime() {
  const options = {
    weekday: 'short',
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    timeZoneName: 'short'
  };
  const now = new Date();
  return now.toLocaleString('en-US', options);
}

export async function fetchDiseaseByCategory(category) {
  try {
    const response = await APIFetch(`api/receptionistApp/getDiseaseByCategory/${category}`);
    return response;
  } catch (error) {
    console.error(error);
    return null;
  }
}

export async function submitInvoice(raw) {
  try {
    const response = await APIPost('api/v3/saveInvoice', raw, { "feature": "Paid" }
    )
    return response
  } catch (error) {
    console.error(error)
    return null
  }
}

export async function uploadFiles(file, appId, paymentMode) {
  const formData = new FormData();
  formData.append('files', file);
  formData.append('appId', appId);
  formData.append('paymentMode', paymentMode);

  try {
    const response = await fetch(`${process.env.REACT_APP_JAVA_BASE_URL_COMMON}/api/receptionistApp/uploadScreenShot`, {
      method: 'POST',
      headers: {
        Authorization: "bearer " + getLocalUser().access_token,
      },
      body: formData
    });

    return await response.json();
  } catch (error) {
    console.error(error);
    return null;
  }
}


export async function getUploadedPrescriptionUrls(uploadData) {

  try {
    const response = await fetch(`${process.env.REACT_APP_JAVA_BASE_URL_COMMON}/api/receptionistApp/procedureFile`, {
      method: 'POST',
      headers: {
        Authorization: "bearer " + getLocalUser().access_token,
      },
      body: uploadData
    });

    return await response.json();
  } catch (error) {
    console.error(error);
    return null;
  }
}

export async function submitPrescriptionFiles(raw) {
  try {
    const response = await APIPost('api/v1/mergeAllImages', raw)
    return response
  } catch (error) {
    console.error(error)
    return null
  }
}


export function isTokenValid() {
  const user = getLocalUser();
  if (!user) {
    console.log("No Data found in local storage");
    return false;
  }
  const accessToken = user.access_token
  let checkValidTokenUrl = `${process.env.REACT_APP_JAVA_BASE_URL_COMMON}/oauth/check_token?token=${accessToken}`;
  const res = fetch(checkValidTokenUrl, {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  })
    .then(handleResponse)
    .then((res) => {
      return true;
    })
    .catch(async (err) => {
      return false
    });
  return res;
}


export function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        return Promise.reject(data);
      }
      return Promise.reject(data);
    }

    return data;
  });
}

export function convertTimestamp(timestamp) {
  const date = new Date(timestamp);

  const options = {
    timeZone: 'Asia/Kolkata',
    hour12: true,
    year: 'numeric',
    month: 'short',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit'
  };

  const dateString = date.toLocaleString('en-US', options);
  const [monthDay, year, time] = dateString.split(', ');
  const [month, day] = monthDay.split(' ');
  let [hourMinute, ampm] = ['', ''];
  if (time) {
    [hourMinute, ampm] = time.split(' ');
  }

  return `${day} ${month} ${year}, ${hourMinute} ${ampm}`;
}

export const convertTimeToUTC = (dateString, time) => {
  let dateArr = dateString?.split('-')
  const year = dateArr[0];
  const month = dateArr[1];
  const day = dateArr[2];

  const hr = parseInt(time.hr, 10);
  const mm = parseInt(time.mm, 10);
  const date = new Date(year, month - 1, day, hr, mm, 0, 0);
  // console.log({date})
  return date.toISOString();
};

export function utcDateFormatToLocalFormat(utcDateTimeString) {
  const utcDate = new Date(utcDateTimeString + ' UTC');
  const options = {
    day: '2-digit',
    month: 'short',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    hour12: true
  };

  return utcDate.toLocaleString('en-IN', options).replace(',', ', ').toLocaleUpperCase();

}

export async function getDoctorSlots(data) {
  try {
    const response = await APIPost(`api/receptionistApp/getDocSlots`, data);
    // console.log("response",response)
    return response;
  } catch (error) {
    console.error(error);
    return null;
  }
}

export async function getDoctorList(clinicId) {
  try {
    const response = await APIFetch(`api/v1/patient-reports/doctorsByClinic?clinicId=${clinicId}`);
    return response;
  } catch (error) {
    console.error(error);
    return null;
  }
}

export async function fetchTableData(data) {
  try {
    const response = await APIPost(`api/v1/patient-reports/appointments`, data , {} , `${process.env.REACT_APP_JAVA_WP}`);
    // console.log("response",response)
    return response;
  } catch (error) {
    console.error(error);
    return null;
  }
}

export async function fetchTotalAmount(data) {
  try {
    const response = await APIPost(`api/v1/patient-reports/opd-total-amount`, data, {} , `${process.env.REACT_APP_JAVA_WP}`);
    // console.log("response",response)
    return response;
  } catch (error) {
    console.error(error);
    return null;
  }
}


export const paymentStatusOptions = [
  { value: 'Pending', label: 'Pending' },
  { value: 'Paid', label: 'Paid' },
  { value: 'all', label: 'All' },
];

export const arrivalStatusOptions = [
  { value: 'arrived', label: 'Arrived' },
  { value: 'notarrived', label: 'Not Arrived' },
  { value: 'doctornotavailable', label: 'Dr. Not Available' },
];

export const handlePrescriptionView = async (link) => {
  const url = await getSignedUrl(link)
  console.log({ url })
  const href = url && url.result ? url.result[0] : ''
  if (url) {
    const anchor = document.createElement('a');
    anchor.href = href;
    anchor.target = '_blank';
    anchor.rel = 'noopener noreferrer';
    anchor.click();
  }
  else {
    Notify.failure('Technical Issue !!!')
  }
}