import React, { useEffect, useState } from 'react';
import "./ReportingFilters.css";
import { TextField } from '@mui/material';
import { arrivalStatusOptions, fetchTableData, fetchTotalAmount, getDoctorList, getLocalUser, paymentStatusOptions } from '../../utility/helper';
import "react-date-range/dist/styles.css"; 
import "react-date-range/dist/theme/default.css"; 
import Select, { components } from 'react-select';
import { DateRange } from "react-date-range";
import enUS from 'date-fns/locale/en-US'; 
import { format, isSameDay, subMonths ,differenceInDays, addMonths, addDays} from 'date-fns';
import ReportingTable from '../ReportingTable/ReportingTable';
import { Loading, Notify } from 'notiflix';
import DateRangeIcon from '@mui/icons-material/DateRange';
import { useClickOutside } from 'react-click-outside';
import debounce from 'lodash.debounce';
const ReportingFilters = () => {
  const [selectedDoctor, setSelectedDoctor] = useState([]);
  const [selectedPaymentStatus, setSelectedPaymentStatus] = useState([]);
  const [selectedArrivalStatus, setSelectedArrivalStatus] = useState([
    { value: 'arrived', label: 'Arrived' }
  ]);
  const [mobile, setMobile] = useState('');
  const [mobileError, setMobileError] = useState('');
  const [doctorOptions, setDoctorOptions] = useState([]);
  const [showDateRange, setShowDateRange] = useState(false); 
  const [tableHeaders, setTableHeaders] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [tableCount, setTableCount] = useState(0);
  const [pageData, setPageData] = useState({
    page:0,
    rowsPerPage:5
  });
  const [totalAmount,setTotalAmount]=useState(0)
  const [dateRangePickerRef, setDateRangePickerRef] = useState(null);
  const [dateRangeChange, setDateChange] = useState(false)
  const [pageChange,setPageChange] = useState(false)

  const [date, setDate] = useState([
    {
      startDate: subMonths(new Date(), 1), 
      endDate: new Date(),
      key: "selection",
    },
  ]);

  const user = getLocalUser();

  useEffect(() => {
    async function fetchDocList(id) {
      const doctorListRes = await getDoctorList(id);
      if (doctorListRes && doctorListRes.length > 0) {
        const tempArr = doctorListRes.map((doc) => ({
          value: doc.id,
          label: doc.name,
        }));
        setDoctorOptions(tempArr);
      } else {
        setDoctorOptions([]);
      }
    }

    if (user?.clinicId) {
      fetchDocList(user?.clinicId);
    }

    handleFiltersChange();

  }, [user?.clinicId]);

  useEffect(() => {
    const handleDocumentClick = (event) => {
      if (dateRangePickerRef && !dateRangePickerRef.contains(event.target)) {
        setShowDateRange(false);
      }
    };
    document.addEventListener('click', handleDocumentClick);
    return () => {
      document.removeEventListener('click', handleDocumentClick);
    };
  }, [dateRangePickerRef]);


  async function getTableData(body) {
    Loading.circle();
    const dataResponse = await fetchTableData(body);
    const totalAmountResponse = await fetchTotalAmount(body)
    // console.log({totalAmountResponse})
    if (dataResponse && dataResponse.headers && dataResponse.headers.length > 0) {
      setTableHeaders(dataResponse.headers);
    }
    if (dataResponse && dataResponse.appointments && dataResponse.appointments.length > 0) {
      setTableData([...dataResponse.appointments]);
    }
    else{
      setTableData([])
    }
    if (dataResponse && dataResponse.totalCount) {
      setTableCount(dataResponse.totalCount);
    }
    else{
      setTableCount(0)
    }
    if(totalAmountResponse){
      let amount = typeof(totalAmountResponse)==='number'? totalAmountResponse : totalAmountResponse.result
      setTotalAmount(amount)
    }
    else{
      setTotalAmount(0)
    }
    Loading.remove()
  }

  function convertISTToUTC(startDateIST, endDateIST) {
    // Create Date objects for the given dates in IST (assumes input is in local timezone)
    const startDate = new Date(startDateIST);
    const endDate = new Date(endDateIST);

    // Set time to the start of the day (00:00:00) in IST
    const startDateISTStartOfDay = new Date(startDate.setHours(0, 0, 0, 0));
    
    // Set time to the end of the day (23:59:59.999) in IST
    const endDateISTEndOfDay = new Date(endDate.setHours(23, 59, 59, 999));

    return {
        startDateUTC:startDateISTStartOfDay,
        endDateUTC: endDateISTEndOfDay
    };
}
  console.log({pageData})
  const handleFiltersChange = (pagination) => {
    if(pageData.page!==0 && !pageChange){
      setPageChange(false)
      return 
    }
    // const startDate = date[0].startDate;
    // const endDate = date[0].endDate;
    const startDate = new Date(date[0].startDate);
    const endDate = new Date(date[0].endDate);

    // Calculate UTC for the startDate (set to start of the day in const startDateUTC = convertISTToUTC(startDateIST);
    const { startDateUTC, endDateUTC } = convertISTToUTC(date[0].startDate, date[0].endDate);
    // Convert to ISO string format for API (UTC timezone)
    // const startDateUTC = startDate.toISOString();
    // const endDateUTC = endDate.toISOString();
    if (!startDate || !endDate) return;
    if (mobile!='' && !validateMobileNumber(mobile)) {
      return;
    }
    // if (!pagination) {
    // }
    const body = {
      startDate: startDateUTC,
      endDate: endDateUTC,
      clinicId: user?.clinicId || "",
      pageNo: pageData?.page + 1,
      pageSize: pageData?.rowsPerPage || 5,
      mobile: mobile || "",
      doctors: selectedDoctor.map(doctor => doctor.value),
      paymentStatus: selectedPaymentStatus.map(status => status.value) // Extract values
      .concat(selectedPaymentStatus.map(status => status.value.toLowerCase())) // Add lowercase values
      .filter(value => value.toLowerCase() !== 'all'),
      arrivalStatus: selectedArrivalStatus.map(status => status.value),
      isCountRequired: true
    };
    setShowDateRange(false)
    getTableData(body);
  };

  useEffect(() => {
    handleFiltersChange();
  }, [selectedDoctor, selectedPaymentStatus, selectedArrivalStatus, mobile, dateRangeChange, pageData]);

  // useEffect(() => {
  //   handleFiltersChange(pageData);
  // }, [pageData]);

  const handleDoctorChange = (selected) => {
    setPageChange(false)
    setSelectedDoctor(selected);
  };

  const handlePaymentStatusChange = (selected) => {
    setPageChange(false)
    setSelectedPaymentStatus(selected);
  };

  const handleArrivalStatusChange = (selected) => {
    setPageChange(false)
    setSelectedArrivalStatus(selected);
  };

  const handleMobileChange = (event) => {
    setPageChange(false)
    const value = event.target.value;
    setMobile(value);
    validateMobileNumber(value);
  };

  const validateMobileNumber = (mobileNumber) => {
    if (mobileNumber === '') {
      setMobileError('');
      return true;
    }
    if (mobileNumber.length !== 10) {
      setMobileError('Mobile number must be 10 digits long');
      return false;
    }
    if (!/^[6-9]/.test(mobileNumber)) {
      setMobileError('Mobile number can only start with 6, 7, 8, or 9');
      return false;
    }
    setMobileError('');
    return true;
  };

  const Option = (props) => (
    <div>
      <components.Option {...props}>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />{" "}
        <label>{props.label}</label>
      </components.Option>
    </div>
  );

  const customSelectStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? '#fff' : provided.backgroundColor,
      color: state.isSelected ? '#000' : provided.color,
      ':active': {
        backgroundColor: '#f0f0f0',
      },
    }),
    control: (provided) => ({
      ...provided,
      borderColor: '#ccc',
      boxShadow: 'none',
      '&:hover': {
        borderColor: '#999',
      },
    }),
    multiValue: (provided) => ({
      ...provided,
      backgroundColor: '#E6F2FB', 
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      color: '#0071BC', 
      fontWeight: '500', 
    }),
  };

  const formattedDate = `${format(date[0]?.startDate, 'dd MMM yyyy')} - ${format(date[0]?.endDate, 'dd MMM yyyy')}`;
  const debouncedHandleFiltersChange = debounce(handleFiltersChange, 2000);
  let timoutForDebounce = null
  const handleDateChange = (item) => {
    setPageChange(false)
    const newDate = item.selection;
    if (newDate.startDate && newDate.endDate) {
      if (differenceInDays(newDate.endDate, newDate.startDate) > 30) {
        Notify.failure('Date range cannot be more than 1 month');
        Loading.remove();
        return;
      } else {
        setDate([newDate])
        if (timoutForDebounce) {
          clearTimeout(timoutForDebounce)
        }
        timoutForDebounce = setTimeout(() => {
          setDateChange(!dateRangeChange)
        },2000)
      }
    }
  };

  // console.log({tableData})
  return (
    <>
      <div className='filter_upperContainer'>
        <div className='filter_container' >
          <p>Select Date</p>
          <div style={{position:'relative'}}>
           <input
            type="text"
            value={formattedDate}
            placeholder="Select Date Range"
            className="date-input"
            onClick={() => setShowDateRange((prev) => !prev)}
            readOnly
            style={{
              cursor: 'pointer',
              padding: '8px',
              paddingLeft:'2rem',
              border: '1px solid #ccc',
              borderRadius: '4px',
              height: '38px',
              color: '#0071BC',
              width:'210px'
            }}
          />
          <DateRangeIcon style={{ position: 'absolute', left: '3%', top: '48%', transform: 'translateY(-50%)' , color:'#0071BC',height:'20px' }} />
          </div>
          {showDateRange && (
            <div style={{ position: 'absolute', zIndex: 1000, marginTop: '5px', top: '28%', left: '10%', boxShadow: '0px 3px 6px #00000029' }} className='reportingFilter_dateRanePicker'>
              <DateRange
                editableDateInputs={false}
                onChange={(item) => handleDateChange(item)}
                moveRangeOnFirstSelection={false}
                ranges={date}
                className="date"
                locale={enUS}
              />
            </div>
          )}
        </div>
        <div className='filter_container'>
          <p>Select Doctor</p>
          <Select
            isMulti
            name="doctors"
            options={doctorOptions}
            value={selectedDoctor}
            onChange={handleDoctorChange}
            className="basic-multi-select"
            classNamePrefix="select"
            placeholder="Select"
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            components={{ Option }}
            menuPortalTarget={document.querySelector('body')}
            styles={{ ...customSelectStyles, menuPortal: base => ({ ...base, zIndex: 9999 }) }}
          />
        </div>
        <div className='filter_container'>
          <p>Payment Status</p>
          <Select
            isMulti
            name="payment"
            options={paymentStatusOptions}
            value={selectedPaymentStatus}
            onChange={handlePaymentStatusChange}
            className="basic-multi-select"
            classNamePrefix="select"
            menuPortalTarget={document.querySelector('body')}
            styles={{ ...customSelectStyles, menuPortal: base => ({ ...base, zIndex: 9999 }) }}
            placeholder="Select Payment Status"
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            components={{ Option }}
          />
        </div>
      </div>
      <div className='filter_upperContainer'>
        <div className='filter_container'>
          <p>Arrival Status</p>
          <Select
            isMulti
            name="arrival"
            options={arrivalStatusOptions}
            value={selectedArrivalStatus}
            onChange={handleArrivalStatusChange}
            className="basic-multi-select"
            classNamePrefix="select"
            menuPortalTarget={document.querySelector('body')}
            styles={{ ...customSelectStyles, menuPortal: base => ({ ...base, zIndex: 9999 }) }}
            placeholder="Select Arrival Status"
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            components={{ Option }}
          />
        </div>
        <div className='filter_container'>
          <p>Mobile</p>
          <TextField
            label="Enter Mobile Number"
            variant="outlined"
            value={mobile}
            onChange={handleMobileChange}
            error={!!mobileError}
            // helperText={mobileError}
            inputProps={{ maxLength: 10, pattern: "[6-9]{1}[0-9]{9}" }}
            size="small"
          />
        </div>
        <div className='filter_totalAmountSection'>
            <p>Total Amount :</p>
            <p style={{color:'#158215',fontWeight:'600'}}>{totalAmount}</p>
        </div>
        <div className='filter_totalAmountSection'>
            <p>Total Patient :</p>
            <p style={{color:'#158215',fontWeight:'600'}}>{tableCount}</p>
        </div>
      </div>
      <ReportingTable
        headers={tableHeaders}
        appointments={tableData}
        tableCount={tableCount}
        pageData={pageData}
        setPageData={setPageData}
        setPageChange={setPageChange}
        pageChange={pageChange}
      />
    </>
  );
};

export default ReportingFilters;
